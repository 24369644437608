import SectionsHead from "../Home/Heading";
import "../../style/partials/components/_privacy-policy.scss";

const Privacypolicy = () => {
  return (
    <div className="container privacy-policy-comman mt-4 mb-5">
      <SectionsHead heading="Privacy Policy" />
      <h2>Last Update : {new Date().toString().slice(4, 15)}</h2>
      <h6>
        This is the Privacy Policy of A2Z which is referred to as "A2Z"
        throughout this Privacy Policy. This Privacy Policy provides details of
        how we Process Personal Data in line with our obligations under the Data
        Protection Law. If you choose to use our service, then you agree to the
        collection and use of information with this policy. The Personal
        Information that we collect is used for providing and improving the
        Service. We will not use or share your information with anyone except as
        described in this Privacy Policy.
      </h6>

      <ul>
        <li>
          <h6> Introduction</h6>- A2Z Counseling Services is an all-type
          department within A2Z, aiming to provide guidance and counselling to
          all people. <br></br>- For matters related to Data Protection
          Legislation, the entity responsible for data control is A2Z Counseling
          Services,59, New Rampura Circle Near BSNL Tower Udaipur, Rajasthan
          313001. You can reach out via mail or by emailing{" "}
          <a href={`mailto:hello@itooze.com.`} className="herf-links">
            hello@itooze.com
          </a>
        </li>

        <li>
          <h6>Information We Collect</h6>
          Directly Provided Information: When you register on A2Z, use the site,
          complete forms, or participate in our programs, we collect the
          information you provide, including email address, login details, name,
          profile details (such as picture, education, profession), contact
          details, date of birth, transactional information, payment details,
          and more.
          <br /> - Automatically Collected Information: As you access, browse,
          and use the site, we automatically collect data like geo-location, IP
          address, device information, operating system, session duration, and
          web-log information. This helps us analyze website performance and
          tailor our services.
        </li>
        <li>
          <h6>How Do We Use the Information Collected</h6>- Quality Service and
          Security: We use personal information to provide high-quality service,
          verify identity, and ensure the security of A2Z. <br />- Marketplace
          Integrity and Fraud Prevention: Personal information is used to
          monitor and prevent fraudulent activities, maintain content integrity,
          and conduct security investigations. <br />- Contact and Marketing
          Communications: We use your information to contact you as requested,
          send direct marketing communications, and promote A2Z services.
          <br /> - Compliance with Laws: Personal information is processed to
          comply with lawful requests, obligations by public authorities, and
          applicable laws and regulations.
        </li>
        <li>
          <h6>How Long Do We Keep Personal Information </h6> - We keep personal
          information for durations necessary to fulfil its purpose, including
          operations, compliance, and preserving evidence within statutes of
          limitation.
        </li>
        <li>
          <h6>Children's Privacy </h6> - A2Z is designed for users above 13
          years and older, and those under 13 are not allowed. Parents should
          supervise their children's activities.
        </li>
        <li>
          <h6>Sharing Personal Information with Third Parties</h6>- Some
          personal information may be processed by third-party suppliers in
          different jurisdictions. We ensure data protection through safeguards{" "}
          <br />- With Other Users: Certain information is made public, such as
          username, country, and activity details. Information is shared with
          other users for better service. <br />
          - With Service Providers: Personal information is shared with service
          providers to operate the site, provide services, and ensure data
          security. <br />- For Legal Reasons: Information may be shared with
          law enforcement or other parties to comply with legal processes or
          protect against fraud.
        </li>
        <li>
          <h6>Cookies</h6> - Cookies are files with a small amount of data that
          are commonly used as anonymous unique identifiers. These are sent to
          your browser from the websites that you visit and are stored on your
          device's internal memory.
        </li>
        <li>
          <h6>Security</h6> We use technical actions such as password protection
          and encryption to protect your data. We also use fixed measures to
          protect the information, for example by limiting the number of people
          who have access to the databases.
        </li>
        <li>
          <h6>Updating Personal Information</h6> - Users can update their
          information through account profile settings. A2Z strives to keep
          information accurate and up-to-date.
        </li>
        <li>
          <h6>Changes to This Privacy Policy </h6> - We may update our Privacy
          Policy from time to time. Thus, you are advised to review this page
          periodically for any modifications. We will notify you of any changes
          by publishing the new Privacy Policy on this page. This policy is
          effective as of dd-mm-yy
        </li>
        <li>
          <h6>Contact Us </h6> If you have any questions or suggestions about my
          Privacy Policy, do not hesitate to contact us at{" "}
          <a href="mailto:hello@itooze.com" className="herf-links">
            hello@itooze.com
          </a>
          ,{" "}
          <a href="tel:+917976768772" className="herf-links">
            +917976768772
          </a>{" "}
          or visit us at 59, New Rampura Circle Near BSNL Tower Udaipur,
          Rajasthan 313001.
        </li>
      </ul>
    </div>
  );
};

export default Privacypolicy;
