import React, { useEffect, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../../../api-config";
import axios from "axios";
import socket from "../../../socket/socketio";
import UserContext from "../../../contexts/UserContext";
import { Modal } from "react-bootstrap";
import Loader from "../../Loader/Loader";
import { head } from "lodash";

let walletBal = 0;
let sufficient = "";
let payMode = false;
const BidPayment = () => {
  const emp = useLocation();
  // const currency = emp.state.defaultCurrency;
  const { userData } = useContext(UserContext);
  const [walletb, setWalletB] = useState(0);
  const [bidDetails, setBidDetails] = useState(null);
  const [isPaymentDone, setPayment] = useState(false);
  const [bidAmount, setBidAmount] = useState(0);
  let counsellingCharge = 0;
  console.log(emp.state);
  const navigate = useNavigate();
  useEffect(() => {
    const findBid = async () => {
      const resd = await axios.post(
        `${API}/api/bidrequest/findBid`,
        {
          bidId: emp.state.bidId,
        },
        {
          headers: {
            token: userData.token,
          },
        }
      );
      console.log(resd);
      setBidDetails((prevState) => resd.data.data);
      counsellingCharge = (resd.data.data.bidAmount * 0.05).toFixed(2);
      setBidAmount((prevState) => counsellingCharge);
    };

    findBid();
  }, []);

  const checkBalance = async () => {
    if (emp.state.entity == "counsellor") {
      const resd = await axios.get(`${API}/api/wallet/checkBalance`, {
        headers: {
          userId: userData.userId,
          sessionId: emp.state.session._id,
          entity: "counsellor",
          token: userData.token,
          bidId: emp.state.bidId,
        },
      });
      console.log("RESDD ", resd, emp);
      sufficient = resd.data.sufficient;
      return resd.data.sufficient;
    } else {
      const resd = await axios.get(`${API}/api/wallet/checkBalance`, {
        headers: {
          userId: userData.userId,
          sessionId: emp.state.session._id,
          entity: "student",
          token: userData.token,
        },
      });
      console.log("RESDD ", resd, emp);
      sufficient = resd.data.sufficient;
      return resd.data.sufficient;
    }
  };

  console.log("WALLET PAYMENT PAGE", emp);

  //WALLET PAYMENT PENDING
  const payViaWallet = async () => {
    try {
      if (window.confirm("Confirm Pay")) {
        setPayment(true);
        const affluent = await checkBalance();
        if (affluent) {
          if (emp.state.entity == "counsellor") {
            try {
              const resd = await axios.post(
                `${API}/api/wallet/paid-via-wallet`,
                null,
                {
                  headers: {
                    userId: userData.userId,
                    bidCost: emp.state.session.sessionCost,
                    sessionId: emp.state.session._id,
                    defaultCurrency: emp.state.defaultCurrency,
                    entity: "counsellor",
                    token: userData.token,
                    bidId: emp.state.bidId,
                  },
                }
              );
              alert("Payment Successful");
              const res = await axios.put(
                `${API}/api/bidrequest/confirm-bidrequest/${emp.state.bidId}`,
                {
                  headers: {
                    token: userData.token,
                  },
                },
                {
                  headers: {
                    token: userData.token,
                  },
                }
              );
              console.log();

              let info = {
                bidId: emp.state.bidId,
                counsellorId: userData.userId,
                userId: emp.state._id,
                sessionId: emp.state.data.state,
                sessiontitle: emp.state.sessiontitle,
                skills: emp.state.session.skills,
                sessionDes: emp.state.session.sessionDes,
                noOfSessions: emp.state.session.slotCount,
                sessionDetails: emp.state.session.sessionNumber,
              };
              let message = emp.state.msg;
              let notificationDetails = {
                msg: message,
                entity: "Session",
              };
              console.log("NOTIFICATION DETAILS", notificationDetails);
              const notificationResponse = await axios.post(
                `${API}/api/notifications/update`,
                { details: notificationDetails, userData: emp.state },
                { headers: { token: userData.token } }
              );
              console.log(
                "NOTIFICATION notificationResponse",
                notificationResponse
              );
              socket.emit("noti", {
                userId: emp.state._id,
                bidId: emp.state.bidId,
                _id: emp.state._id,
                msg: emp.state.msg,
              });
              socket.emit("sessionDecision", {
                userId: emp.state._id,
                bidId: emp.state.bidId,
                _id: emp.state._id,
                msg: emp.state.msg,
                status: "ACCEPTED",
              });
              setPayment(false);
              navigate(`/event-create`, {
                state: {
                  state: info,
                  sessions: emp.state.session.sessionNumber,
                  leftBal: emp.state.session.sessionCost,
                },
              });
            } catch (error) {
              console.log(error);
              alert("Error In Payment!Check Balance & Retry");
              setPayment(false);
              throw new Error("Error in counsellor fee charge");
            }
          } else {
            try {
              const res = await axios.post(
                `${API}/api/wallet/paid-via-wallet`,
                null,
                {
                  headers: {
                    userId: userData.userId,
                    bidCost: emp.state.session.sessionCost,
                    sessionId: emp.state.session._id,
                    defaultCurrency: emp.state.defaultCurrency,
                    entity: "student",
                    token: userData.token,
                  },
                }
              );
              setPayment(true);
              const response = await axios.post(
                `${API}/api/bidrequest/create`,
                { bidData: emp.state.datas },
                { headers: { token: userData.token } }
              );
              const notificationResponse = await axios.post(
                `${API}/api/notifications/update`,
                { details: emp.state.details, userData: emp.state.userData },
                { headers: { token: userData.token } }
              );
              //missing transaction updates

              alert("Payment Succcessful");
              socket.emit("noti", {
                _id: emp.state._id,
                msg: emp.state.msg,
                userId: emp.state._id,
                bidId: emp.state.bidId,
              });
              socket.emit("sessionRequest", {
                _id: emp.state._id,
                msg: emp.state.msg,
                data: emp.state.data,
                bidId: emp.state.bidId,
              });
              setPayment(false);
              navigate("/students", { state: response.data });
            } catch (error) {
              alert("Error In Payment!Check Balance & Retry");
              setPayment(false);
              console.log(error);
              throw new Error(error);
            }
          }
        } else {
          setPayment(false);
          payMode = false;
          alert("LOW BALANCE");
          navigate("/");
        }
      }
    } catch (error) {
      setPayment(false);
      console.log(error);
    }
  };

  return (
    <div className="container">
      {isPaymentDone && (
        <Modal show={isPaymentDone}>
          <Loader />
        </Modal>
      )}
      <div class="top-title mb-3 pt-5">
        <h2 class="heading faq-page-heading">Payment Page</h2>
      </div>

      <div className="pt-5 pb-5 row">
        <div className="col-md-8 col-10 m-auto shadow rounded">
          <div className="row p-3">
            <div className="col-md-6">
              <label className="">Session Name:</label>
              <p className="primary-text"><b>Abc session</b></p>
            </div>
            <div className="col-md-6">
              <label className="">Created Date:</label>
              <p className="primary-text"><b>17 Oct 2024</b></p>
            </div>
            <div className="col-md-6">
              <label className="">Session by:</label>
              <p className="primary-text"><b>Rajat Soni</b></p>
            </div>
            {/* { <p>Wallet Balance : {walletb}</p>} */}
            {(bidDetails || 1) && (
              <div className="col-md-6">
                <label className="">Session Cost:</label>
                <p className="primary-text"><b>{" "}
                {emp.state.entity == "counsellor"
                  ? bidAmount
                  : emp.state.session.sessionCost}</b></p>
              </div>
            )}
          </div>
          

          <div className="row border-top">
              <div className="col-md-12">
                <button className="primary-bg border-0 py-2 px-4 w-auto text-white rounded"
                  onClick={async () => {
                    const affluent = await checkBalance();
                    if (affluent) {
                      if (payMode) {
                        return;
                      }
                      payMode = true;
                      payViaWallet();
                      setTimeout(() => {
                        payMode = false;
                      }, 4000);
                    } else {
                      console.log("LOW WALLET BALANCE");
                      alert("RETRY ! Low Wallet Balance");
                      navigate("/");
                    }
                  }}
                >
                  CONFIRM PAYMENT
                </button>
              </div>
            </div>
        </div>
      </div>
      
    </div>
  );
};
export default BidPayment;

// let info = {
//   bidId: emp.state.bidId,
//   counsellorId: userData.userId,
//   userId: emp.state._id,
//   sessionId: emp.state.data.state,
//   sessiontitle: emp.state.sessiontitle,
//   skills: emp.state.session.skills,
//   sessionDes: emp.state.session.sessionDes,
//   noOfSessions: emp.state.session.slotCount,
//   sessionDetails: emp.state.session.sessionNumber,
//   userId: userData.userId,
//   bidCost: emp.state.session.sessionCost,
//   sessionId: emp.state.session._id,
//   defaultCurrency: emp.state.defaultCurrency,
//   entity: "counsellor",
// };