import { React, useState, useEffect, useContext } from "react";
import "../../../style/partials/components/_wallet.scss";
import UserContext from "../../../contexts/UserContext";
import axios from "axios";
import { API } from "../../../api-config";
import Walletadd from "../../../data/Walletamount";
import { useNavigate } from "react-router-dom";
let addThrotC = false;
let withdrawThrotC = false;
const Studentwallet = () => {
  const Navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [wallet, setWallet] = useState([]);
  const [transacts, setTransacts] = useState([]);
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState(0);
  const [currencyError, setCurrencyError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [deductCurrency, setDeductCurrency] = useState("");
  const [deductAmount, setDeductAmount] = useState(0);
  const [showWithdrawl, setShowWithdrawl] = useState(false);
  const [successnotification, setSuccessNotification] = useState("");
  const [dangernotification, setDangerNotification] = useState("");
  const { userData } = useContext(UserContext);
  useEffect(() => {
    console.log("currency,amount", currency, amount);
  }, [currency, amount]);
  const updateUsers = async () => {
    try {
      console.log(userData.userId);
      const res = await axios.get(
        `${API}/api/wallet/get-amounts/${userData.userId}`,
        {
          userId: userData.userId,
          headers: {
            token: userData.token,
          },
        }
      );
      setWallet((prevState) => {
        const sorted = res.data.sort((a, b) =>
          a.currencyCode.localeCompare(b.currencyCode)
        );
        console.log("SORTED", sorted);
        return sorted;
      });
      console.log("RREEESS  ", res.data);
      const transactions = await axios.get(
        `${API}/api/transaction/get-userTransactions`,
        { headers: { userId: userData.userId, token: userData.token } }
      );
      setTransacts(transactions.data);
      console.log(transactions);
    } catch (error) {
      console.log(error);
    }
  };
  const addingAmount = async () => {
    try {
      console.log("amount > 0 && currency", amount, currency);
      if (amount > 0 && currency != "") {
        const walletUpdated = await axios.post(
          `${API}/api/wallet/addWalletAmounts`,
          { userId: userData.userId, amount: amount, currency: currency },
          { headers: { token: userData.token } }
        );
        console.log("UPDATED WALLET DATA", walletUpdated);
        //window.location.reload();
        setSuccessNotification("Amount successfully added to your wallet!");
        updateUsers();
        setShow(false);
        setCurrency("");
        setAmount(0);
      }else {
        // Show error message if input is invalid
        if (!currency) setCurrencyError(true);
        if (amount <= 0) setAmountError(true);
      }
    } catch (error) {
        console.log(error);
        setDangerNotification("Failed to add amount. Please try again.");
    }
  };
  const deductingAmount = async () => {
    try {
      console.log("amount > 0 && currency", deductAmount, deductCurrency);
      if (deductAmount > 0 && deductCurrency != "") {
        const walletUpdated = await axios.post(
          `${API}/api/wallet/deductWalletAmounts`,
          {
            userId: userData.userId,
            amount: deductAmount,
            currency: deductCurrency,
          },
          {
            headers: { token: userData.token },
          }
        );
        console.log("UPDATED WALLET DATA", walletUpdated);
        //window.location.reload();

        //window.location.reload();
        setSuccessNotification(
          "Amount successfully deducted from your wallet!"
        );
        updateUsers();
        setShowWithdrawl(false);
        setCurrency("");
        setAmount(0);
      }
    } catch (error) {
      setDangerNotification("Insufficient Balance");
      console.log(error);
    }
  };
  useEffect(() => {
    updateUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //console.log(wallet,"amopunt")

  const addBalance = async (id, amount, addamount) => {
    console.log(id, "id");
    console.log(addamount, "  amount  ", amount);
    try {
      const id = {
        bidAmount: addamount,
      };
      const response = await axios.put(
        `${API}/api/wallet/add-amounts/${userData.userId}`,
        { id: id, amount: addamount },
        {
          headers: {
            token: userData.token,
          },
        }
      );
      console.log(response);
      window.location.reload();
      setShow(show);
    } catch (err) {
      console.log(err, "Accept");
    }
  };

  return (
    <>
      {successnotification && (
        <div className="container mb-3">
          <div className="row">
            <div className="alert alert-success alert-dismissible">
              {" "}
              <a
                onClick={() => {
                  setSuccessNotification("");
                }}
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {successnotification}
            </div>
          </div>
        </div>
      )}

      {dangernotification && (
        <div className="container mb-3">
          <div className="row">
            <div className="alert alert-danger alert-dismissible">
              {" "}
              <a
                onClick={() => {
                  setDangerNotification("");
                }}
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {dangernotification}
            </div>
          </div>
        </div>
      )}

      <div className="container center-algne mb-4">
        <div className="wallet-card">
          {wallet.map((x, index) => (
            <div className="row mt-3">
              <div className="col-6">
                <h5>{x.currencyCode}</h5>
              </div>
              <div className="col-6 wallet-amount">
                <h3 key={index}>{x.balance}</h3>
                <p>in {x.currencyCode}</p>
              </div>
            </div>
          ))}

          <button
            className="wallet-amount-btn w-100 mb-2"
            type="button"
            onClick={() =>
             Navigate("/withdrawal")
              /*setShowWithdrawl(() => {
                setShowWithdrawl(() => !showWithdrawl);
                setShow((prevState) => false);
                setCurrencyError(false);
                setAmountError(false);
              })*/
            }
          >
            Withdrawal Money from Wallet
          </button>
          {showWithdrawl ? (
            <div className="row ">
              <div className="col-12 mb-2">
                <select
                  className={`wallet-amount-btn btn-primary w-100 mb-2 ${
                      currencyError ? "is-invalid" : ""
                    }`}
                  onChange={(event) => {
                    setDeductCurrency(() => event.target.value); // First statement
                    setCurrencyError(false); // Second statement
                  }}
                >
                  <option selected disabled>
                    {"Select Currency"}
                  </option>
                  {wallet.map((x, index) => (
                    <option>{x.currencyCode}</option>
                  ))}
                </select>
                {currencyError && (
                  <div className="invalid-feedback pb-3">
                    Please select a currency
                  </div>
                )}
              </div>
              {Walletadd.map((item, index) => (
                <div className="col-6 mb-2" key={index}>
                  <button
                    className={`wallet-amount-btn w-100 ${
                      amountError ? "is-invalid" : ""
                    }`}
                    type="button"
                    onClick={() => {
                      setDeductAmount(() => item.amount);
                      setAmountError(false);
                    }}
                  >
                    {item.amount}
                  </button>
                </div>
              ))}
              {amountError && (
                <div className="invalid-feedback d-block pb-3">
                  Please select an amount
                </div>
              )}
              <div className="col-12 mb-3">
                <button
                  className="wallet-amount-btn w-100"
                  onClick={() => {
                    if (!deductCurrency && deductAmount == 0) {
                      setCurrencyError(true);
                      setAmountError(true);
                      return;
                    }
                    if (!deductCurrency) {
                      setCurrencyError(true);
                      return;
                    }
                    if (deductAmount == 0) {
                      setAmountError(true);
                      return;
                    }

                    if (withdrawThrotC) {
                      return;
                    }
                    withdrawThrotC = true;
                    setTimeout(() => {
                      withdrawThrotC = false;
                    }, 5000);
                    deductingAmount();
                  }}
                >
                  Withdraw Amount
                </button>
              </div>
            </div>
          ) : null}
          <button
            className="wallet-amount-btn w-100 mb-2"
            type="button"
            onClick={() => {
              Navigate("/wallet",{ state: { userType: 'student'}});
              // setShow(!show);
              // setShowWithdrawl(false);
            }}
          >
            Add Money To Wallet
          </button>
          {/* {show ? (
            <div className="row ">
              <select
                className="wallet-amount-btn btn-primary w-100 mb-2"
                onChange={(event) => setCurrency(() => event.target.value)}
              >
                <option selected disabled>
                  {"Select Currency"}
                </option>
                {wallet.map((x, index) => (
                  <option>{x.currencyCode}</option>
                ))}
              </select>
              {Walletadd.map((item, index) => (
                <div className="col-6 mb-2" key={index}>
                  <button
                    className="wallet-amount-btn w-100"
                    type="button"
                    onClick={() => {
                      setAmount(() => item.amount);
                    }}
                  >
                    {item.amount}
                  </button>
                </div>
              ))}
              <button
                className="wallet-amount-btn w-100"
                onClick={() => {
                  if (addThrotC) {
                    return;
                  }
                  addThrotC = true;
                  setTimeout(() => {
                    addThrotC = false;
                  }, 5000);
                  addingAmount();
                }}
              >
                Add Amount
              </button>
            </div>
          ) : null} */}
          {/* <h6 className="mt-3">Transaction History</h6>
          <div>
            <table className="table">
              <tbody>
                {transacts.map((item) => {
                  // console.log(item);
                  return (
                    <tr>
                      <td>{item.createdAt.toString().substring(0, 10)}</td>
                      <td>{`Paid to ${item.counsellorId.fName} ${item.counsellorId.lName}`}</td>
                      <td>{item.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Studentwallet;
