const menuItems = [
  {
    title: "Find Skills",
    url: "/top-skills",
    cName: "nav-links",
  },
  {
    title: "Find Counsellors",
    url: "/find-counsellor",
    cName: "nav-links",
  },
  {
    title: "Why A2Z",
    url: "/why-a2z",
    cName: "nav-links",
  },
  {
    title: "About A2Z",
    url: "/about-us",
    cName: "nav-links",
  },
  {
    title: "FAQs",
    url: "/faqs",
    cName: "nav-links",
  },
  {
    title: "Contact US",
    url: "/contact-us",
    cName: "nav-links",
  },
];

export default menuItems;
