import React, { useEffect, useState, useCallback  } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { API } from "../../../api-config";
import "../../../style/partials/components/_popularsess.scss";
import ai from "../../../images/a2z-bg.jpg";
import SectionsHead from "../../Home/Heading";

const PopularSessions = () => {
  const navigate = useNavigate();
  const emp = useLocation();
  const [sessions, setSessions] = useState([]);
  const loadSessions = useCallback(async () => {
    if (emp.state.entity === "skill") {
      const response = await axios.post(
        `${API}/api/session/popularSkillsSessions`,
        {
          skill: emp.state.skill,
        }
      );
      setSessions(response.data.sessions);
    } else if (emp.state.entity === "counsellor") {
      const response = await axios.post(`${API}/api/session/get-session`, {
        counsellorId: emp.state.counsellor,
      });
      setSessions(response.data);
    }
  }, [emp.state.skill, emp.state.entity, emp.state.counsellor]);
  useEffect(() => {
    loadSessions();
  }, [loadSessions]);
  return (
      <div className="container mt-5 mb-5 sess">
        <SectionsHead heading="Popular Sessions" />
       
        {sessions.length > 0 ? (
           <div className="row pt-3 pb-3">
          {sessions &&
            sessions.map((item, index) => (
              <Card key={index} style={{border: "4px solid", borderColor: "#3a4f68", textAlign: "center", padding: "0px",}} className="col-lg-3 col-md-6 col-sm-12 top-1">
                <div className="m-2 border rounded">
                  <Card.Img variant="top" src={item.image ? item.image : ai} style={{ height: "330px" }}/>
                  <div className="p-2">
                    <Card.Body>
                      <label className="h4 primary-text" style={{ textAlign: "center" }}>{`${item.sessiontitle}`}</label>
                      <p>
                        {`Session Cost : `}<b>{`${item.sessionCost} ${item.defaultCurrency ? item.defaultCurrency : ''}`}</b>
                      </p>
                    </Card.Body>
                    <div className="d-flex justify-content-between">

                      <Button className="border-0 primary-bg" onClick={() => { navigate(`/session-details/${item._id}`);}}>
                        Check Slots
                      </Button>
                      <Button className="border-0" style={{ backgroundColor: "#3a4f68", width: "fit-content" }}
                      onClick={() => { navigate(`/session-details/${item._id}`);}}
                      >
                        Book Session
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        ) : (
          <div className="row pt-3 pb-3"><h6 className="text-center">No Popular sessions available in this domain</h6></div>
        )}
      </div>
  );
};

export default PopularSessions;
