import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../../style/partials/Counsellor/_counsellorprofile.scss";
import { API } from "../../../api-config";
import UserContext from "../../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { useForm } from "react-hook-form";
import teacher from "../../../images/bg2.png";
import SectionsHead from "../../Home/Heading";
let skillsid = [];
let allskills = [];
let skillsdropdown = [];
function UpdateProfile() {
  const { userData } = useContext(UserContext);
  const Navigate = useNavigate();
  const emp = useLocation();
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [company, setcompany] = useState("");
  const [skills, setskills] = useState("");
  const [skillsname, setSkillsName] = useState([]);
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [selectstate, setselectstate] = useState("");
  const [selectcountry, setselectcountry] = useState("");
  const [zip, setzip] = useState("");
  const [number, setnumber] = useState(null);
  const [photo, setphoto] = useState("");
  const [country, setcountry] = useState([]);
  const [state, setstate] = useState([]);
  const [getskill, setgetskill] = useState([]);
  const [reqSkill, setReqSkill] = useState(false);
  const [portfolio, setPortfolio] = useState([]);
  const [selectedPortfolios, setselectedPortfolios] = useState([]);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    try {
      const updateUsers = async () => {
        let myData = await axios.get(
          `${API}/api/counselor-profile/getCounselor`,
          {
            params: { id: userData.userId },
            headers: {
              token: userData.token,
            },
          }
        );
        console.log("SKILLS CHECK", myData);
        setValue("fname", myData.data[0].userId.fName);
        setValue("lname", myData.data[0].userId.lName);
        setValue("company", myData.data[0].company);
        setskills(myData.data[0].skills.map((item,index) => item.skill));
        setValue("address", myData.data[0].address);
        setValue("description", myData.data[0].description);
        setValue("city", myData.data[0].city);
        setValue("state", myData.data[0].state);
        setselectcountry(myData.data[0].country);
        setValue("country", myData.data[0].country);
        setValue("zipcode", myData.data[0].zip);
        setValue("phone1", myData.data[0].phone1);
        let selectedCountryId = myData.data[0].country ? myData.data[0].country : null;
        if (selectedCountryId) {
          await axios.post(`${API}/api/state/get-statebycountryname`, {
            countryName: selectedCountryId,
          }).then((response) => {

            console.log("countryData", response.data)
            setstate(response.data);
          });
 
        }
        setselectstate(myData.data[0].state);
        setphoto(myData.data[0].profilePic[0]);
        setPortfolio(myData.data[0].portfolio);
        axios.post(`${API}/api/skills/getSkillsList`, {
            category: myData.data[0].category,
            subcategory: myData.data[0].subcategory,
          })
          .then((response) => {
            setgetskill(response.data);
            skillsdropdown = response.data;
            allskills = [];
            response.data.map((item, index) => {
              if (skillsid.includes(item._id)) {
                allskills.push({ skill: item.skill, id: item._id });
              }
            });
            setSkillsName(allskills);
          });
        skillsid = myData.data[0].skills.map((item, index) => item._id);
      };

      updateUsers();
    } catch (error) {
      console.log("UPDATE PROFILE DATA LOADING ERROR", error);
    }
  }, []);
  const [selectedImage, setSelectedImage] = useState([]);
  // Handler for file input change event
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setphoto(imageUrl);
    setSelectedImage((prevState) => [file]);
  };
  const handleChangePortfolio = (e) => {
    setselectedPortfolios((prevState) => [...e.target.files]);
  };

  const uploadChunk = async (
    file,
    chunk,
    chunkIndex,
    context,
    uploadId,
    partNumber,
    parts
  ) => {
    //console.log(parts);
    const formData = new FormData();
    //console.log("CHUNK", chunk);
    formData.append("file", chunk);
    formData.append("fileName", file.name);
    formData.append("chunkIndex", chunkIndex);
    formData.append("context", context);
    formData.append("uploadId", uploadId);
    formData.append("partNumber", partNumber);
    formData.append("parts", JSON.stringify(parts));
    //console.log(formData);
    try {
      const response = await axios.post(
        `${API}/api/counselor-profile/uploadChunk`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: userData.token,
          },
        }
      );
      //console.log(response.data);
      return response.data.parts;
    } catch (error) {
      console.error("Error uploading chunk:", error);
    }
  };
  const uploadFiles = async () => {
    let context = "";
    let files = "";
    const formData = new FormData();
    if (selectedPortfolios && selectedImage) {
      files = [selectedImage, selectedPortfolios];
      context = ["profilePic", "portfolio"];
    } else if (selectedImage) {
      files = [selectedImage];
      context = ["profilePic"];
    } else if (selectedPortfolios) {
      files = [selectedPortfolios];
      context = ["portfolio"];
    }
    const CHUNK_SIZE = 1024 * 1024;

    files.forEach(async (item, index) => {
      item.forEach(async (file, index2) => {
        let keyName = `/${userData.userId}/${Date.now()}-${context[index]}/${
          file.name
        }`;
        //console.log(index, item, file, keyName);
        const responseInitiation = await axios.post(
          `${API}/api/counselor-profile/initiatemultipart`,
          { keyName: keyName },
          {
            headers: {
              token: userData.token,
            },
          }
        );
        const uploadId = responseInitiation.data.uploadId;
        let parts = [];
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        //console.log(totalChunks);
        for (let i = 0; i < totalChunks; i++) {
          const start = i * CHUNK_SIZE;
          const end = Math.min(start + CHUNK_SIZE, file.size);
          const chunk = file.slice(start, end);
          const response = await uploadChunk(
            file,
            chunk,
            i,
            keyName,
            uploadId,
            i + 1,
            parts
          );
          parts = response;
        }
        //console.log("ONe FILE UPLOADED");
        await axios.post(
          `${API}/api/counselor-profile/upload-complete`,
          {
            keyName: keyName,
            uploadId: uploadId,
            parts: parts,
            fileName: file.name,
            context: context[index],
          },
          {
            headers: {
              token: userData.token,
            },
          }
        );
      });
    });
  };

  const submit = (e) => {
    try {
      if (skillsid.length <= 0) {
        return;
      }
      const post = {
        fName: e.fname,
        lName: e.lname,
        company: e.company,
        skills: skillsid,
        address: e.address,
        description:e.description,
        city: e.city,
        state: e.state,
        country: e.country,
        zip: e.zipcode,
        number: e.phone1,
        portfolio: portfolio,
      };

      axios
        .put(`${API}/api/counselor-profile/counselor-update/${emp.state}`, post)
        .then((res) => {
          if (selectedPortfolios || selectedImage) {
            uploadFiles();
          }
          Navigate("/counsellor-profile");
        });
    } catch (error) {
      //console.log("ERROR", error);
      alert(error);
    }
  };
  
  const getcountry = async () => {
    let myData = await axios.get(`${API}/api/country/get-Country`);

    setcountry(myData.data);
  };
  useEffect(() => {
    getcountry();
  }, []);

  const handlecountry = (value) => {
    let countryid = country.find((key) => key.countryName === value);
    setselectstate(() => "");
    let selectedCountryId = countryid ? countryid._id : null;
    if (selectedCountryId) {
      getstate(selectedCountryId);
    }
  };

  const getstate = async (getcountryid) => {
    let myData = await axios.post(`${API}/api/state/get-statebycountry`, {
      countryId: getcountryid,
    });

    setstate(myData.data);
  };
  // debugger;
  const handlestate = (event) => {
    const getcountryid = event.target.value;
    setselectstate(getcountryid);
  };
  const updateSelectedSkill = () => {
    let names = [];
    getskill.map((item, index) => {
      if (skillsid.includes(item._id)) {
        names.push({ skill: item.skill, id: item._id });
      }
    });
    setSkillsName(() => {
      return names;
    });
  };
  const handleChangeId = () => {
    let names = [];
    const skill = document.getElementById("skill");
    if(skill.value != ''){
      //console.log(skillsid);
      skillsid.push(skill.value);
      updateSelectedSkill();
      skill.value = ''; 
    }

    // if (skillsid.some((id) => id == skill.value)) {
    //   skillsid = skillsid.filter((ids) => ids != skill.value);
    //   updateSelectedSkill();
    // getskill.map((item, index) => {
    //   if (skillsid.includes(item._id)) {
    //     console.log(item._id);
    //     console.log(item.skill);
    //     names.push({ skill: item.skill, id: item._id });
    //     console.log(names);
    //   }
    // });
    // console.log(names);
    // setSkillsName(names);
    // } else {

    // getskill.map((item, index) => {
    //   if (skillsid.includes(item._id)) {
    //     console.log(item.skill);
    //     names.push({ skill: item.skill, id: item._id });
    //     console.log(names);
    //   }
    // });
    // setSkillsName(names);
    // console.log(skillsid);
    // }
    // console.log(skillsid);
    // console.log(skillsname);
  };
  const handleSelect = (selectedList) => {
    //console.log("SELECTED SKILL", selectedList);
    setskills(selectedList);
  };
  const handleRemove = (selectedList) => {
    setskills(selectedList);
  };

  return (
    <>
      <section className="p-3 update-profile">
        <SectionsHead heading="Update Profile" />
        <div className="profile-main-title text-center mt-1"></div>
        <form
          className=" counselling-profile profile-form  container shadow"
          onSubmit={handleSubmit(submit)}
        >
          <div className="row text-center">
            <div className=" col-md-6 ">
              <div className="shadow-sm update-image-box">
                <label htmlFor="profile-img">
                  <input
                    id="profile-img"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                 
                  <img
                    className="profile-img"
                    alt="profile-img"
                    src={photo}
                    loading="lazy"
                    style={{ cursor: "pointer" }}
                  />
                  
                </label>
              </div>
              <input
                className="input-field "
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("fname", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                  maxLength: 20,
                  pattern: /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
                })}
              />
              {errors?.fname?.type === "required" && (
                <p className="valid-txt">Please Enter First Name</p>
              )}
              {errors?.fname?.type === "pattern" && (
                <p className="valid-txt">Alphabetical characters only</p>
              )}
              <input
                className="input-field"
                type="text"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("lname", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                  maxLength: 20,
                  pattern: /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z][a-zA-Z\s]*$/,
                })}
              />
              {errors?.lname?.type === "required" && (
                <p className="valid-txt">Please Enter Last Name</p>
              )}
              {errors?.lname?.type === "pattern" && (
                <p className="valid-txt">Alphabetical characters only</p>
              )}
              <input
                className="input-field"
                type="text"
                name="phone1" 
                placeholder="Alternate Contact Number"
                onKeyDown={(e) => {
                  if (
                    e.keyCode === 38 || // Up arrow
                    e.keyCode === 37 || // Left arrow
                    e.keyCode === 39 || // Right arrow
                    e.keyCode === 40 // Down arrow
                  ) {
                    e.preventDefault(); // Prevent arrow key navigation
                  }
                  // Prevent typing e, ., +, except allow + as the first character
                  if (
                    (e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-') &&
                    (e.target.selectionStart !== 0 || e.key !== '+') // Only allow + at the start
                  ) {
                    e.preventDefault();
                  }
                }}
                onInput={(e) => {
                  let value = e.target.value;

                  // Remove all invalid characters except digits and the + sign at the start
                  value = value.replace(/(?!^\+)\D/g, '');

                  // Ensure + is only at the start
                  if (value[0] !== '+') {
                    value = value.replace(/\+/g, ''); // Remove any + that isn't at the start
                  }

                  // Enforce max length of 13 characters
                  if (value.length > 13) {
                    value = value.slice(0, 13);
                  }

                  e.target.value = value; // Set the cleaned value
                }}
                onWheel={(e) => {
                  e.preventDefault(); // Prevent mouse wheel input
                }}
                {...register("phone1", {
                  required: "Enter the mobile number", // Error message for required field
                  minLength: {
                    value: 10,
                    message: "Min 10 digits allowed", // Error message for minimum length
                  },
                  maxLength: {
                    value: 13,
                    message: "Max 13 digits allowed", // Error message for maximum length
                  },
                  validate: (value) => value !== "",
                })}
              />
              {errors?.phone1 && (
                <p className="valid-txt">{errors.phone1.message}</p>
              )}
              <input
                className="input-field"
                type="text"
                name="company"
                placeholder="Company"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("company", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                  maxLength: 20,
                })}
              />
                {errors?.company?.type === "required" && (
                <p className="valid-txt">Please Enter the company</p>
              )}
              <select id="skill" onChange={() => handleChangeId()}>
                <option value="">Select Skills</option>
                {getskill.map((item, index) => (
                  <option key={item._id || index} value={item._id}>{item.skill}</option>
                ))}
              </select>
              {skillsname && (
                <div className="bord-div">
                  {skillsname.map((item, index) => {
                    return (
                      <div key={item.id || index} className="skill-add-div">
                        <p>{item.skill}</p>
                        <button
                          type="button"
                          onClick={() => {
                            console.log(
                              "ENTERED SKILLS REMOVING",
                              item.id,
                              item
                            );
                            skillsid = skillsid.filter((ids) => ids != item.id);
                            skillsdropdown = skillsdropdown.filter(
                              (ids) => ids != item.id
                            );
                            console.log(skillsid);
                            updateSelectedSkill();
                            skillsid.length <= 0
                              ? setReqSkill((prevState) => true)
                              : setReqSkill((prevState) => false);
                            console.log(skillsdropdown, reqSkill);
                          }}
                        >
                          X
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}

              {reqSkill && skillsid.length <= 0 && (
                <p className="valid-txt">Minimum 1 skill is mandatory</p>
              )}
            </div>
            {/* <div className=" col-md-6"> */}

            {/* <input
                className="input-field "
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("fname", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                  maxLength: 20,
                  pattern: /^[a-zA-Z\s]*[a-zA-Z][a-zA-Z\s]*$/,
                })}
              />
              {errors?.fname?.type === "required" && (
                <p>Please Enter FirstName</p>
              )}
              {errors?.fname?.type === "pattern" && (
                <p>Alphabetical characters only</p>
              )} */}

            {/* </div> */}
            <div className=" col-md-6">
              <textarea
                className="input-field"
                type="text"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("address", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                })}
                placeholder="Address"
              />
              {errors?.address && <p className="valid-txt">Please enter address</p>}

              <textarea
                className="input-field"
                type="text"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("description", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                })}
                placeholder="Description"
              />
              {errors?.description && <p className="valid-txt">Please enter Description</p>}
              <select
                {...register("country", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                })}
                onChange={(e) => {
                  handlecountry(e.target.value);
                }}
              >
                <option defaultValue hidden>
                  {selectcountry}
                </option>
                {country.map((getcon, index) => {
                  return <option key={getcon._id || index}>{getcon.countryName}</option>;
                })}
              </select>

              {errors?.country?.type == "required" && <p className="valid-txt">Select Country</p>}
              <select
                {...register("state", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                })}
              >
                {selectstate && (
                  <option defaultValue hidden>
                    {selectstate}
                  </option>
                )}
                {state.map((getste, index) => {
                  return (
                    <option key={index} value={getste.stateName}>
                      {getste.stateName}
                    </option>
                  );
                })}
              </select>
              {errors?.state && <p className="valid-txt">Select State</p>}
              <input
                className="input-field"
                onKeyPress={(e) => {
                  if (e.target.value === "" && e.key === " ") {
                    e.preventDefault();
                  }
                }}
                {...register("city", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                })}
                placeholder="City"
                name="city"
              />
              {errors?.city && <p className="valid-txt">Enter City Name</p>}
              <input
                className="input-field"
                type="number"
                placeholder="Zip Code"
                onKeyDown={(e) => {
                  console.log(e.key, " ,", e.keyCode);
                  if (
                    e.keyCode == 38 ||
                    e.keyCode == 37 ||
                    e.keyCode == 39 ||
                    e.keyCode == 40
                  ) {
                    e.preventDefault();
                  }
                }}
                {...register("zipcode", {
                  required: true,
                  maxLength: 8,
                  validate: (value) => {
                    if (value !== "" && value.length <= 8) {
                      return true;
                    }
                  },
                })}
              />
              {errors?.zipcode?.type === "required" && <p className="valid-txt">Enter the zipcode</p>}
              {errors?.zipcode?.type === "validate" && (
                <p className="valid-txt">Max 8 digit allowed</p>
              )}
              {errors?.zipcode?.type === "maxLength" && (
                <p className="valid-txt">Max 8 digit allowed</p>
              )}
            </div>
            <div>
              <h3>Portfolio</h3>
              {portfolio &&
                portfolio.map((item, index) => (
                  <div key={item} className="skill-add-div">
                    <img
                      src={item}
                      style={{ height: "82px" }}
                      alt="skill"
                      loading="lazy"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setPortfolio((prevState) =>
                          prevState.filter((link) => link != item)
                        );
                        console.log(skillsdropdown, reqSkill);
                      }}
                    >
                      X
                    </button>
                   
                  </div>
                ))}
                 
            </div>
            
            <div>
              <label htmlFor="protfo">
                <input
                  id="portfo"
                  type="file"
                  accept="image/*,application/pdf,"
                  multiple
                  onChange={handleChangePortfolio}
                />
              </label>
            </div>
            {/* <span className="text-danger text-small">Only .png fomrat allowed.</span> */}
            <div className="mt-3 mb-3">
              <button type="submit" className="update-profile">
                Update Profile
              </button>
            </div>
           
            {/* <div className=" col-md-6">

              </div>
              
              <div className=" col-md-6">
              {errors?.company && <p>Enter Company Name</p>}


              </div>

              <div className=" col-md-6">



              </div> */}
            {/* <div className=" col-md-6  col-12  image-box-prfile main-profile-updt-div ">
              <div className="blur-bg-profile"></div>
              <div className="img-contanent-btn">
                <img src={teacher} className="profile-img" alt="profile-img" />
              </div>

              <button type="submit" className="profile-sub-btn">
                Update Profile
              </button>
            </div> */}

            {/* <div className="profile-input col-md-6 col-12 text-center "> */}

            {/* <Multiselect
                onKeyPress={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                id="ID"
                name="item"
                className="bord-div"
                isObject={false}
                selectedValues={skills}
                onRemove={handleRemove}
                onSelect={handleSelect}
                placeholder="Select Skills"
                options={getskill.map((item, index) => item.skill)}
                showArrow
              /> */}
            {/* {errors?.skills && <p>Enter some skills</p>} */}

            {/* </div> */}
          </div>
        </form>
      </section>
    </>
  );
}

export default React.memo(UpdateProfile);
