import React, { useEffect, useState } from "react";
import SectionsHead from "../../Home/Heading";
import profile from "../../../images/a2z-bg.jpg";
import axios from "axios";
import "../../../style/partials/components/profile.scss";
import { API } from "../../../api-config";
import { useParams } from "react-router-dom";
import ai from "../../../images/a2z-bg.jpg";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
function CounsellorDetails() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [sessions, setSessions] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    counselorProfile();
  }, [id]);

  const counselorProfile = async () => {
    try {
      let response = await axios.get(
        `${API}/api/counselor-profile/getdetails`,
        {
          params: { id },
        }
      );
      setData(response.data);
      const userId = response.data[0]?.userId._id;
      if (userId) {
        counselorSession(userId);
      } else {
        console.error("userId not found in response");
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const counselorSession = async (user_id) => {
    try {

      const sessionsData = await axios.post(`${API}/api/session/get-counsellor-session`, {
        user_id: user_id,
      });

      setSessions(sessionsData.data);
    } catch (error) {
      console.error("Error in counselorSession:", error.response || error.message);
    }
  };

  return (
    <>
    <section className="container mt-5 mb-5">
      {data &&
        data.map((x, index) => {
          return (
            <div className="container profile-cart p-3 col-lg-12  shadow-sm mt-4" key={index}>
              <div className="row">
                <div className="col-12 col-md-12 id-card">
                  <div className="col-12 col-md-6 id-card-det">
                    <div className="col-4 col-md-4 col-md-3">
                      <div className="profile-cart-img text-center">
                          <img
                            className="hero-image"
                            src={x?.profilePic && x.profilePic.length > 0 ? x.profilePic[0] : profile}
                             alt={`${x?.userId[0]?.fName} ${x?.userId[0]?.lName}`}
                            loading="lazy"
                          />
                      </div>
                    </div>

                    <div className="col-8 col-md-8 align-self-center">
                      <div className="profile-header-detail ">
                        <h6>
                          {x.userId?.fName.charAt(0).toUpperCase() +
                            x.userId.fName.slice(1) +
                            " " +
                            x.userId?.lName.charAt(0).toUpperCase() +
                            x.userId?.lName.slice(1)}
                        </h6>
                        <div className="pt-2 pb-2 time-left flex-wrap">
                          <label className="fw-bolder">Skills: </label>
                          {x?.skills?.map((ele, index2) => (
                            <p className="m-0 ml-1" key={`${ele.skill}${index2}`}>
                              {ele.skill}
                              {index2 < x.skills.length - 1 ? ',  ' : ''}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 align-self-center">
                  <p>{x.description}</p>
                </div>
              </div>
            </div>
          );
        })
      }
    </section>
      <div className="container mt-3 mb-3 sess">
        <SectionsHead heading="Sessions" />
       
        {sessions.length > 0 ? (
           <div className="row pt-3 pb-3">
          {sessions &&
            sessions.map((item, index) => (
              <Card key={index} style={{border: "4px solid", borderColor: "#3a4f68", textAlign: "center", padding: "0px",}} className="col-lg-3 col-md-6 col-sm-12 top-1">
                <div className="m-2 border rounded">
                  <Card.Img variant="top" src={item.image ? item.image : ai} style={{ height: "330px" }}/>
                  <div className="p-2">
                    <Card.Body>
                      <label className="h4 primary-text" style={{ textAlign: "center" }}>{`${item.sessiontitle}`}</label>
                      <p>
                        {`Session Cost : `}<b>{`${item.sessionCost} ${item.defaultCurrency ? item.defaultCurrency : ''}`}</b>
                      </p>
                    </Card.Body>
                    <div className="d-flex1">

                      <Button className="border-0" style={{ backgroundColor: "#3a4f68", width: "fit-content" }}
                      onClick={() => { navigate(`/session-details/${item._id}`);}}
                      >
                        Book Session
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        ) : (
          <div className="row pt-3 pb-3"><h6 className="text-center">No sessions available in this domain</h6></div>
        )}
      </div>


    </>
  );
}
export default React.memo(CounsellorDetails);
