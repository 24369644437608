import { React, useState, useContext } from "react";
import "../../style/partials/components/_wallet.scss";
import SectionsHead from "../Home/Heading";
import UserContext from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import paypal from "../../images/paypal.png";
import bank_transfer from "../../images/bank_transfer.png";

const PreWithdrawal = () => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate(); // Use navigate for redirection

  const [withdrawalMethod, setWithdrawalMethod] = useState("bank_transfer");
  const [error, setError] = useState(null);


  function selectWithdrawalMethod(withdrawalMethod) {
    navigate(`/withdrawal`, { state: withdrawalMethod })
  }


  return (
    <div className="container mt-5 mb-5">
      <SectionsHead heading="Withdrawal Request" />
      <div className="row pt-2 pb-2">
        <div className="col-md-8 offset-md-2">
            <div className="form-group row">
              <label htmlFor="withdrawalMethod" className="mb-4 mt-5">Select Withdrawal Method</label>

              <div className="col-sm-4 mb-3">
                <div onClick={() => selectWithdrawalMethod('paypal')} className="cursor-pointer d-flex flex-column text-center border shadow py-3 px-2 rounded">
                  <img
                    src={paypal}
                    className="w-25 m-auto feature-box-icon small icn-holder"
                    alt="certify"
                    loading="lazy"
                  ></img>
                  <label className="cursor-pointer primary-text mt-3"><b>PayPal</b></label>
                </div>
              </div>

              <div className="col-sm-4 mb-3">
                <div onClick={() => selectWithdrawalMethod('bank_transfer')} className="cursor-pointer d-flex flex-column text-center border shadow py-3 px-2 rounded">
                  <img
                    src={bank_transfer}
                    className="w-25 m-auto feature-box-icon small icn-holder"
                    alt="certify"
                    loading="lazy"
                  ></img>
                  <label className="cursor-pointer primary-text mt-3"><b>Bank Transfer</b></label>
                </div>
              </div>

              {/* <select
                id="withdrawalMethod"
                className="form-control"
                value={withdrawalMethod}
                onChange={(e) => setWithdrawalMethod(e.target.value)}
              >
                <option value="bank_transfer">Bank Transfer</option>
                <option value="paypal">PayPal</option>
              </select> */}
            </div>
            
            {error && <div className="text-danger mt-2">{error}</div>}

            {/* <button type="submit" className="btn btn-primary mt-3">
              Continue to Withdrawal
            </button> */}
        </div>
      </div>
    </div>
  );
};

export default PreWithdrawal;
