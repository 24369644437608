import { React, useState, useEffect, useContext } from "react";
import "../../style/partials/components/_wallet.scss";
import SectionsHead from "../Home/Heading";
import UserContext from "../../contexts/UserContext";
import { API } from "../../api-config"; // Adjust based on your actual API path
import axios from "axios";
import { useLocation, useNavigate  } from "react-router-dom";

const Withdrawal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const withdrawalMethod = location.state;
  const { userData } = useContext(UserContext);
  const [bankDetails, setBankDetails] = useState(null);
  const [withdrawals, setWithdrawals] = useState({});
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);
  const [conversionRates, setConversionRates] = useState({});
  const [wallet, setWallet] = useState([]); // Store wallet amounts
  const [formData, setFormData] = useState({
    account_holder_name: '',
    account_number: '',
    withdrawals:'',
    bank_name: '',
    account_type: '',
    currency: '',
    country: '',
    ifsc_code: '',      // For India
    routing_number: '',  // For USA
    sort_code: '',      // For UK
    iban: '',           // For Kuwait
    paypal_email: '',
  });

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  const accountTypeOptions = {
    India: ["Savings", "Current"],
    USA: ["Checking", "Savings", "Business"],
    UK: ["Personal", "Business"],
    Kuwait: ["Savings", "Current", "Joint"],
  };

  const countryToCurrencyMap = {
    India: ["INR"],
    USA: ["USD"],
    UK: ["GBP"],
    Kuwait: ["KWD"]
  };


  const fetchBankDetails = async () => {
    try {
      // Fetch bank details
      const bankResponse = await axios.get(`${API}/api/bank-details/get-bankdetails/user/${userData.userId}`);
      
      if (bankResponse.status === 200 && bankResponse.data) {
        setBankDetails(bankResponse.data); // Bank details found

        setFormData({
          account_holder_name: bankResponse.data.account_holder_name || '',
          account_number: bankResponse.data.account_number || '',
          bank_name: bankResponse.data.bank_name || '',
          account_type: bankResponse.data.account_type || '',
          currency: bankResponse.data.currency || '',
          country: bankResponse.data.country || '',
          ifsc_code: bankResponse.data.ifsc_code || '',
          routing_number: bankResponse.data.routing_number || '',
          sort_code: bankResponse.data.sort_code || '',
          iban: bankResponse.data.iban || ''
        });

        fetchConversionRate(bankResponse.data.currency);
      } else {
        setBankDetails(null); // No bank details found
      }

    } catch (err) {
      console.error("Error fetching bank details or wallet balance:", err);
    }
  };

  const fetchWalletData = async () => {
    // Fetch wallet balance
    const response = await axios.get(`${API}/api/wallet/get-amounts/${userData.userId}`, {
      headers: {
        token: userData.token,
      },
    });


    return response.data;
  };

  const fetchConversionRate = async (currency) => {
    const conversionResponse = await axios.get(`${API}/api/rateConverter/conversion-rate/${currency}`);
    if (conversionResponse.status === 200 && conversionResponse.data) {
  
      setConversionRates(conversionResponse.data); // Set state with new conversion rates

    } 
  };

  useEffect(() => {
    if (withdrawalMethod) {
      if (withdrawalMethod === "bank_transfer") {
        fetchBankDetails();
        refreshWalletData();
      }else if (withdrawalMethod === "paypal") {
        refreshWalletData();
        setFormData((prevData) => ({
          ...prevData,
          ['currency']: 'USD',
        }));
        fetchConversionRate('USD');

      }else{
        navigate(`/pre-withdrawal`);
      }

    }else{
      navigate(`/pre-withdrawal`);
    }
  }, [userData.userId, userData.token, withdrawalMethod]);


  const refreshWalletData = async () => {
    fetchWalletData().then(walletData => {
      setWallet((prevState) => {
        const sorted = walletData.sort((a, b) =>
          a.currencyCode.localeCompare(b.currencyCode)
        );
        return sorted;
      });

      const initialWithdrawals = walletData.reduce((acc, item) => {
        acc[item.currencyCode] = item.balance; // Set withdrawal amount to current balance
        return acc;
      }, {});
      setWithdrawals(initialWithdrawals); // Set initial withdrawal amounts
      const newTotalWithdrawal = calculateTotalWithdrawal();
      setTotalWithdrawal(newTotalWithdrawal);
    });
  };  


  const handleChange = (e) => {
    const { name, value } = e.target; // Change 'event' to 'e'

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Check if the country has changed
    if (name === 'country') {
      // Get the currency based on selected country
      const selectedCurrency = countryToCurrencyMap[value] ? countryToCurrencyMap[value][0] : '';

      setFormData((prevData) => ({
        ...prevData,
        currency: selectedCurrency,
      }));

      fetchConversionRate(selectedCurrency);
    }

    if (name === 'currency') {
      fetchConversionRate(value);
    }
  };

  // Validate form
  const validateForm = () => {
    let formErrors = {};

    if (withdrawalMethod === "bank_transfer") {
      if (!formData.account_holder_name.trim()) {
        formErrors.account_holder_name = "Account holder name is required";
      } else if (!/^[A-Za-z\s]+$/.test(formData.account_holder_name)) {
        formErrors.account_holder_name = "Account holder name must contain only letters";
      } else if (formData.account_holder_name.length > 100) { // Maximum length
        formErrors.account_holder_name = "Account holder name must not exceed 100 characters";
      }

      if (!formData.account_number.trim()) {
        formErrors.account_number = "Account number is required";
      } else if (formData.account_number.length > 50) { // Maximum length
        formErrors.account_number = "Account number must not exceed 50 characters";
      }

      if (!formData.bank_name.trim()) {
        formErrors.bank_name = "Bank name is required";
      } else if (formData.bank_name.length > 100) { // Maximum length
        formErrors.bank_name = "Bank name must not exceed 100 characters";
      }

      if (!formData.account_type.trim()) formErrors.account_type = "Account type is required";
      if (!formData.currency.trim()) formErrors.currency = "Currency is required";
      if (!formData.country.trim()) formErrors.country = "Country is required";

      // Country-specific validations
      if (formData.country === "India" && !formData.ifsc_code.trim()) {
        formErrors.ifsc_code = "IFSC code is required for India";
      }
      if (formData.country === "USA" && !formData.routing_number.trim()) {
        formErrors.routing_number = "Routing number is required for USA";
      }
      if (formData.country === "UK" && !formData.sort_code.trim()) {
        formErrors.sort_code = "Sort code is required for UK";
      }
      if (formData.country === "Kuwait" && !formData.iban.trim()) {
        formErrors.iban = "IBAN is required for Kuwait";
      }
    }else{

      if (!formData.paypal_email.trim()) {
        formErrors.paypal_email = "Paypal Email Address is required";
      } else {
        // Email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (!emailRegex.test(formData.paypal_email)) {
          formErrors.paypal_email = "Please enter a valid email address";
        }
        
        // Email length validation
        const emailLength = formData.paypal_email.trim().length;
        if (emailLength < 5 || emailLength > 50) {
          formErrors.paypal_email = "Email address must be between 5 and 50 characters";
        }
      }
    }

    const amount = calculateTotalWithdrawal();
    if (amount <= 0) {
      formErrors.totalWithdrawal = "The withdrawal amount must be greater than 0";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const amount = calculateTotalWithdrawal();

    if (validateForm()) {
      try {
        await axios.post(`${API}/api/withdrawal/create`, { ...formData, withdrawalMethod, user_id: userData.userId, withdrawals, amount });
        alert("Withdrawal request successfully submitted!");

        await refreshWalletData();

      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          // Display the original error message from the server
          setError(err.response.data.error);
        } else {
          // Fallback for unexpected errors
          setError("Failed to withdrawal amount request.");
        }
      }
    }
  };

  const handleInputChange = (currencyCode, value, balance) => {
    const withdrawalValue = parseFloat(value) || 0;

    // Check if withdrawal value exceeds available balance
    if (withdrawalValue > parseFloat(balance)) {
      alert(`Withdrawal amount exceeds the available balance for ${currencyCode}.`);
      return;
    }

    // Update withdrawals state
    setWithdrawals((prev) => ({
      ...prev,
      [currencyCode]: withdrawalValue,
    }));

   // Recalculate the total withdrawal
    const newTotalWithdrawal = calculateTotalWithdrawal();
    setTotalWithdrawal(newTotalWithdrawal);
  };

  const calculateTotalWithdrawal = () => {
    return Object.entries(withdrawals).reduce((total, [currency, amount]) => {
      const conversionRate = conversionRates[currency] || 1; // Default to 1 if currency is not found
      const convertedAmount = amount * conversionRate;
      return parseFloat((total + convertedAmount).toFixed(2));
    }, 0);
  };

  return (
    <div className="container mt-5 mb-5">
      <SectionsHead heading="Withdrawal Request" />
      <div className="row pt-2 pb-2">
        {wallet.length > 0 && (
          <>
            <div className="col-md-12">
              <h3>Current Wallet Balance</h3>
                {wallet.map((walletItem) => (
                  <div key={walletItem.currencyCode} className="row">
                    <div className="col-md-3" >
                      {walletItem.currencyCode}: {walletItem.balance} {walletItem.currencySymbol}
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        name="currencyCode"
                        className="form-control"
                        value={withdrawals[walletItem.currencyCode] !== undefined ? withdrawals[walletItem.currencyCode] : walletItem.balance}
                        onChange={(e) =>
                          handleInputChange(walletItem.currencyCode, e.target.value, walletItem.balance)
                        }
                      />
                    </div>
                    <div className="col-md-3">

                      {formData.currency && ( // Check if formData.currency has a value
                        <div>
                          Converted: {((withdrawals[walletItem.currencyCode] || 0) * (conversionRates[walletItem.currencyCode] || 1)).toFixed(2)} {formData.currency}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="row mt-3">
                {formData.currency && (
                <>
                  <div className="col-md-12">
                    <h4>Total Withdrawal Amount: {calculateTotalWithdrawal().toFixed(2)} {formData.currency}</h4>
                  </div>
                  </>
                  )}
                </div>
            </div>
          </>
        )}
      </div>

      <div className="row pt-2 pb-2">
        <div>
          <form onSubmit={handleSubmit}>
            {error && <div className="alert alert-danger">{error}</div>}
            {errors.totalWithdrawal && <div className="alert alert-danger">{errors.totalWithdrawal}</div>}
            {withdrawalMethod === "bank_transfer" && (
              <>
                <div className="row">
                  <div className="col-md-6 pt-2 pb-0">
                    <div className="form-group">
                      <label className="label">Bank Country</label>
                      <select
                        name="country"
                        className="form-control"
                        value={formData.country}
                        onChange={handleChange}
                      >
                        <option value="">Select Country</option>
                        <option value="India">India</option>
                        <option value="USA">USA</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="UK">UK</option>
                      </select>
                      {errors.country && <div className="text-danger">{errors.country}</div>}
                    </div>
                    </div>
                  {formData.country === 'India' && (
                    <div className="col-md-6 pt-2 pb-0">
                      <div className="form-group">
                        <label className="label">IFSC Code</label>
                        <input
                          type="text"
                          name="ifsc_code"
                          className="form-control"
                          value={formData.ifsc_code}
                          onChange={handleChange}
                        />
                        {errors.ifsc_code && <div className="text-danger">{errors.ifsc_code}</div>}
                      </div>
                    </div>
                  )}

                  {formData.country === 'USA' && (
                    <div className="col-md-6 pt-2 pb-0">
                      <div className="form-group">
                        <label className="label">Routing Number</label>
                        <input
                          type="text"
                          name="routing_number"
                          className="form-control"
                          value={formData.routing_number}
                          onChange={handleChange}
                        />
                        {errors.routing_number && <div className="text-danger">{errors.routing_number}</div>}
                      </div>
                    </div>
                  )}

                  {formData.country === 'UK' && (
                    <div className="col-md-6 pt-2 pb-0">
                      <div className="form-group">
                        <label className="label">Sort Code</label>
                        <input
                          type="text"
                          name="sort_code"
                          className="form-control"
                          value={formData.sort_code}
                          onChange={handleChange}
                        />
                        {errors.sort_code && <div className="text-danger">{errors.sort_code}</div>}
                      </div>
                    </div>
                  )}

                  {formData.country === 'Kuwait' && (
                    <div className="col-md-6 pt-2 pb-0">
                      <div className="form-group">
                        <label className="label">IBAN</label>
                        <input
                          type="text"
                          name="iban"
                          className="form-control"
                          value={formData.iban}
                          onChange={handleChange}
                        />
                        {errors.iban && <div className="text-danger">{errors.iban}</div>}
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-6 pt-2 pb-0">
                    <div className="form-group">
                      <label className="label">Account Holder Name</label>
                      <input
                        type="text"
                        name="account_holder_name"
                        className="form-control"
                        value={formData.account_holder_name}
                        onChange={handleChange}
                      />
                      {errors.account_holder_name && <div className="text-danger">{errors.account_holder_name}</div>}
                    </div>
                  </div>

                  <div className="col-md-6 pt-2 pb-0">
                    <div className="form-group">
                      <label className="label">Account Number</label>
                      <input
                        type="text"
                        name="account_number"
                        className="form-control"
                        value={formData.account_number}
                        onChange={handleChange}
                      />
                      {errors.account_number && <div className="text-danger">{errors.account_number}</div>}
                    </div>
                  </div>

                  <div className="col-md-6 pt-2 pb-0">
                    <div className="form-group">
                      <label className="label">Bank Name</label>
                      <input
                        type="text"
                        name="bank_name"
                        className="form-control"
                        value={formData.bank_name}
                        onChange={handleChange}
                      />
                      {errors.bank_name && <div className="text-danger">{errors.bank_name}</div>}
                    </div>
                  </div>

                  <div className="col-md-6 pt-2 pb-0">
                    <div className="form-group">
                      <label className="label">Account Type</label>
                      <select
                        name="account_type"
                        className="form-control"
                        value={formData.account_type}
                        onChange={handleChange}
                      >
                        <option value="">Select Account Type</option>
                        {formData.country && accountTypeOptions[formData.country]?.map((type, index) => (
                          <option key={index} value={type}>{type}</option>
                        ))}
                      </select>
                      {errors.account_type && <div className="text-danger">{errors.account_type}</div>}
                    </div>
                  </div>

                  <div className="col-md-6 pt-2 pb-0">
                    <div className="form-group">
                      <label className="label">Currency</label>
                      <select
                        name="currency"
                        className="form-control"
                        value={formData.currency}
                        onChange={handleChange}
                      >
                        <option value="">Select Currency</option>
                        {formData.country && countryToCurrencyMap[formData.country]?.map((type, index) => (
                          <option key={index} value={type}>{type}</option>
                        ))}
                      </select>
                      {errors.currency && <div className="text-danger">{errors.currency}</div>}
                    </div>
                  </div>
                </div>
              </>
            )}
  
            {withdrawalMethod === "paypal" && (
              <div className="row">
                <div className="col-md-6 pt-2 pb-0">
                  <div className="form-group">
                    <label className="label">PayPal Email</label>
                    <input
                      type="email"
                      name="paypal_email"
                      className="form-control"
                      value={formData.paypal_email}
                      onChange={handleChange}
                    />
                    {errors.paypal_email && <div className="text-danger">{errors.paypal_email}</div>}
                  </div>
                </div>
              </div>
            )}

            <button type="submit" className="btn btn-primary mt-3">Submit Withdrawal Request</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Withdrawal;
