 import React, { useCallback ,useEffect, useState, useContext } from "react";
import { API } from "../../../api-config";
import axios from "axios";
import SectionsHead from "../../Home/Heading";
import "..//..//..//style/partials/Students/_sessiondetails.scss";
import Loader from "../../Loader/Loader";
import UserContext from "../../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import useRazorpay from "react-razorpay";
import socket from "../../../socket/socketio";
import { Modal } from "react-bootstrap";

let wait = false;
let amountToBeAdded = 0;
function Sessiondetails() {
  const {
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const { userData } = useContext(UserContext);
  //const [isDisabled, setDisabled] = useState(false);
  const [users, setUsers] = useState([]);
  const [bids, setBids] = useState([]);
  const [isPaymentDone, setPayment] = useState(false);
  const { id } = useParams();
  let emp = useLocation();
  const currency = '';
  const getSessionDetails = useCallback(async () => {
    try {
      const myData = await axios.get(`${API}/api/session/get-session/${id}`, {
        headers: { token: userData.token },
      });
      setUsers(myData.data);
      const updatedBids = myData.data.sessionNumber.map((item) => ({
        ...item,
        requestPay: false,
        paid: false,
      }));

      setBids(updatedBids); // Store bids in state

    } catch (error) {
      console.error("Error fetching session details:", error);
    }
  }, [id, userData.token]);


  useEffect(() => {
    if (!userData?.userId) {
      navigate("/login");
    } else {
      getSessionDetails();
    }
    if (userData?.flag === "COUNSELLOR") {
      navigate("/counsellor");
    }
  }, [userData?.userId, userData?.flag, navigate, getSessionDetails]);


  if (users.length === 0) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  let sufficient = "";
  const checkBalance = async () => {
    getSessionDetails();
    const resd = await axios.get(`${API}/api/wallet/checkBalance`, {
      headers: {
        userId: userData.userId,
        sessionId: users._id,
        entity: "Student",
        token: userData.token,
      },
    });
    sufficient = resd.data.sufficient;
    amountToBeAdded = resd.data.amountToBeAdded;
    if (!sufficient) {
      console.log("Insufficient balance");
    }

    return sufficient;
  };

  const submitData = async (data) => {
    await new Promise((resolve, reject) => {
      console.log(userData);
      if (!userData.userId) {
        //console.log("NOT LOGGED IN");
        navigate("/login");
        resolve();
      }
      resolve();
    });
    //console.log("data", data);
    data.sessionid = users._id;
    data.counselorid = users.counsellorId._id;
    data.userId = userData.userId;
    data.sessionNumber = users.sessionNumber.length;
    data.bidAmount = users.sessionCost;
    data.sessionSlots = bids;
    data.leftBalance = users.sessionCost;
    //console.log(users);
    let message = `${userData.fName} ${userData.lName} requested counselling on ${users.sessiontitle}`;
    let userid = {
      _id: users.counsellorId._id,
    };
    let notificationDetails = {
      msg: message,
      entity: "Session",
    };
    try {
      //setDisabled(true);
      let paymentDecision = await checkBalance();
      if (paymentDecision) {
        navigate("/session-pay", {
          state: {
            datas: data,
            session: users,
            _id: userid._id,
            msg: message,
            defaultCurrency: users.defaultCurrency,
            details: notificationDetails,
            userData: userid,
            data: emp,
            entity: "",
          },
        });
      } else {
        let userId = {
          _id: userData.userId,
        };
        let counselorid = {
          _id: users.counsellorId._id,
        };
        let id = { bidAmount: amountToBeAdded, currency: currency };
        let paymentDetails = await axios.get(
          `${API}/api/wallet/pay-counselling`,
          {
            params: {
              id: id,
            },
            headers: {
              userId: userData.userId,
              sessionId: users._id,
              token: userData.token,
            },
          }
        );
        //console.log(" Math.ceil(amountToBeAdded)", Math.ceil(amountToBeAdded));
        var options = {
          key: process.env.RAZORPAY_KEY_ID,
          order_id: paymentDetails.data.order.id,
          amount: Math.ceil(amountToBeAdded),
          // currency: `${userData.defaultCurrency}`,
          currency: `INR`,
          name: "Ooze IT Solutions Pvt Ltd",
          handler: async function (paymentDetails) {
            setPayment(true);
            try {
              const status = "SUCCESS";
              let details = {
                ...paymentDetails,
                ...id,
                status,
                userId,
                sessionId: users._id,
                _id: users._id,
                counselorid,
                amount: amountToBeAdded,
                currency: users.defaultCurrency,
                transactionType: "CREDIT",
                transactionVia: "Razorpay",
              };
              //console.log("ADDED TO RAZORPAY", details);
              await axios.post(
                `${API}/api/transaction/insert-transaction`,
                { data: { ...details } },
                {
                  headers: {
                    token: userData.token,
                  },
                }
              );
              //console.log("TRANSACTION ADDED FOR RAZORPAY");
              const PaymentProcess = async () => {
                //amount add hoga as per currency
                debugger;
                await axios.put(
                  `${API}/api/wallet/add-amounts/${userData.userId}`,
                  {
                    id: id,
                    userId: userData.userId,
                    sessionId: users._id,
                    amount: amountToBeAdded,
                  },
                  {
                    headers: {
                      token: userData.token,
                    },
                  }
                );
                await axios.post(
                  `${API}/api/wallet/paid-via-wallet`,
                  null,
                  {
                    headers: {
                      userId: userData.userId,
                      sessionId: users._id,
                      bidCost: users.sessionCost,
                      sessionCurrency: users.defaultCurrency,
                      entity: "student",
                      token: userData.token,
                    },
                  }
                );
                //console.log("ADDED TO ADMIN PAID VIA WALLET", res);
                await axios.post(
                  `${API}/api/bidrequest/create`,
                  { bidData: data },
                  { headers: { token: userData.token } }
                );
                //console.log("BID REQUEST CREATED", response);
                await axios.post(
                  `${API}/api/notifications/update`,
                  { details: notificationDetails, userData: userid },
                  {
                    headers: { token: userData.token },
                  }
                );

                socket.emit("noti", { _id: userid._id, msg: message });
                socket.emit("sessionRequest", {
                  _id: userid._id,
                  msg: message,
                  data: emp,
                });
                socket.emit("noti", { _id: userData.userId });
                setPayment(false);
                alert("Payment Successful");
                navigate("/students");
              };
              PaymentProcess();
            } catch (error) {
              //console.log(error);
              setPayment(false);
              alert(error.message);
              navigate("/select");
            }
          },
        };

        const rzp1 = new Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", async (response) => {
          const status = "FAILED";

          let details = { ...response, ...id, status };
          await axios.post(
            `${API}/api/transaction/insert-transaction`,
            { data: details },
            {
              headers: { token: userData.token },
            }
          );
          alert(response.error.code);
        });
      }
    } catch (err) {
      //console.log(err, "Accept");
    }
  };

  // const throttleSubmitData = throttle(submitData, 2000);

  return (
    <>
      {isPaymentDone && (
        <Modal show={isPaymentDone}>
          <Loader />
        </Modal>
      )}
      <section className="bid mt-5 mb-5">
      <SectionsHead heading="Book a Session" />
        <div className="contianer row">
          <div className="submit-bid col-md-8">
          <div className="shadow p-3">
            <div className="mb-3 ">
              <h5 className=" primary-text">Session Detail</h5>
            </div>
            <div className="row sess-clr">
                <div className="col-md-6">
                  <label>Session Name:</label> 
                  <p className="h5 primary-text"><b>{users.sessiontitle}</b></p> {/* No nested <p> */}
                </div>

                <div className="col-md-6">
                  <label>Created date:</label> 
                  <p className="h5 primary-text"><b>{moment(new Date(users.createdAt)).fromNow()}</b></p> {/* No nested <p> */}
                </div>

                <div className="col-md-6">
                  <label>Session By:</label> 
                  <p className="h5 primary-text"><b>
                    {users?.counsellorId.fName.charAt(0).toUpperCase() +
                      users.counsellorId.fName.slice(1)}</b>
                  </p> {/* No nested <p> */}
                </div>
              <div className="mt-1 col-md-6">
                {" "}
                <label>Session Cost :</label>{" "}
                <p className="h5 primary-text"><b>{`${users.sessionCost} ${users.defaultCurrency}`}</b></p>
              </div>
            </div>
            <div className="text-justify col-lg-12 mt-3">
              <label className="">Desrciption : </label>
              <p className="h6 primary-text text-break"><b>{users.sessionDes}</b></p>
            </div>
            </div>
          </div>

          <div className="submit-bid col-md-4">
            <div className="shadow p-3">
              <div className="mb-3 ">
                <h5 className=" primary-text">Make a bid for your session?</h5>
              </div>
              <form
                onSubmit={handleSubmit((data) => {
                  if (wait) {
                    return;
                  }
                  wait = true;
                  setTimeout(() => {
                    wait = false;
                  }, 5000);
                  submitData(data);
                })}
              >
              <div className="row sess-clr">
                {users.sessionNumber.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="col-md-12">
                        <label className="mt-2">Session Slot:</label> 
                        <p className="h5 primary-text"><b>{item.sessions}</b></p> 
                      </div>
                      <div className="col-md-12 border-bottom">
                        <label>Session Description:</label> 
                        <p className="h6 primary-text mb-3 text-break"><b>{item.description}</b></p> 
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>

                <div className="mt-3">
                  <button type="submit" className="bid-submit primary-bg border-0 p-1 text-white rounded">
                    Book My Request
                  </button>
                </div>
              </form> 
          </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Sessiondetails;