import React, { useState, useRef, useEffect } from "react";
import { Dropdown, Form } from "react-bootstrap";
import axios from "axios";
import { API } from "../../api-config";
import { Rating } from "react-simple-star-rating";
import "../../style/partials/components/_SearchSection.scss";
import Button from "react-bootstrap/Button";
// import e from "cors";

let selectedCategory = [];
let selectedSubCategory = [];
let selectedSkills = [];
let selectedRatings = [];
const ratings = [3, 4];
let ratingDem = null;
let keyword = null;

const SearchFilter = (props) => {
  const [cats, setCats] = useState([]);
  const [subCateg, setSubCateg] = useState([]);
  const [allsubCats, setallsubCats] = useState([]);
  const [selectedCats, setSelectedCats] = useState([]);
  const [skillsLists, setSkillsLists] = useState([]);
  const [results, setResult] = useState();
  const [categoriesAll, setCategoriesAll] = useState([]);
  const [skillsAll, setSkillsAll] = useState([]);
  const [subCategoriesAll, setSubCategoriesAll] = useState([]);
  const [ratingdata, setRatingdata] = useState([]);
  const [updateDom, setUpdateDom] = useState(1);
  const [showAll, setShowAll] = useState(false);
  const [showSubCatAll, setShowSubCatAll] = useState(false);
  const [showSkillAll, setShowSkillAll] = useState(false);
  const [changeSearch, setChangeSearch] = useState(false);
  const [keyword, setKeyword] = useState('');
  const visibleCategories = showAll 
  ? cats.sort((a, b) => {
      const isASelected = selectedCategory.includes(a._id);
      const isBSelected = selectedCategory.includes(b._id);
      if (isASelected && !isBSelected) return -1;
      if (!isASelected && isBSelected) return 1;
      return a.category.localeCompare(b.category); // Sort alphabetically
    })
  : cats
      .sort((a, b) => {
        const isASelected = selectedCategory.includes(a._id);
        const isBSelected = selectedCategory.includes(b._id);
        if (isASelected && !isBSelected) return -1;
        if (!isASelected && isBSelected) return 1;
        return a.category.localeCompare(b.category); // Sort alphabetically
      })
      .slice(0, 10);

  const visibleSubCategories = showSubCatAll 
  ? subCateg.sort((a, b) => {
      const isASubSelected = selectedSubCategory.includes(a._id);
      const isBSubSelected = selectedSubCategory.includes(b._id);
      if (isASubSelected && !isBSubSelected) return -1;
      if (!isASubSelected && isBSubSelected) return 1;
      return a.name.localeCompare(b.name); // Sort alphabetically
    })
  : subCateg
      .sort((a, b) => {
        const isASubSelected = selectedSubCategory.includes(a._id);
        const isBSubSelected = selectedSubCategory.includes(b._id);
        if (isASubSelected && !isBSubSelected) return -1;
        if (!isASubSelected && isBSubSelected) return 1;
        return a.name.localeCompare(b.name); // Sort alphabetically
      })
      .slice(0, 10);

  const visibleSkills = showSkillAll 
  ? skillsLists.sort((a, b) => {
      const isASkillSelected = selectedSkills.includes(a._id);
      const isBSkillSelected = selectedSkills.includes(b._id);
      if (isASkillSelected && !isBSkillSelected) return -1;
      if (!isASkillSelected && isBSkillSelected) return 1;
      return a.skill.localeCompare(b.skill); // Sort alphabetically
    })
  : skillsLists
      .sort((a, b) => {
        const isASkillSelected = selectedSkills.includes(a._id);
        const isBSkillSelected = selectedSkills.includes(b._id);
        if (isASkillSelected && !isBSkillSelected) return -1;
        if (!isASkillSelected && isBSkillSelected) return 1;
        return a.skill.localeCompare(b.skill); // Sort alphabetically
      })
      .slice(0, 10);

  useEffect(() => {
    if (Array.isArray(props.categories) && props.categories.length > 0) {
      setCats((prevState) => props.categories[0]);
      setCategoriesAll((prevState) => props.categories[0]);
      setSkillsAll((prevState) => props.categories[2]);
      setSubCategoriesAll((prevState) => props.categories[1]);
      setSubCateg((prevState) => props.categories[1]);
      setSkillsLists((prevState) => props.categories[2]);
    }

    if (Array.isArray(props.filters) && props.filters.length > 0) {
      if (props.filters[0] !== undefined && props.filters[0] !== null) {
        selectedCategory = props.filters[0]
      }
      if (props.filters[1] !== undefined && props.filters[1] !== null) {
        selectedSubCategory = props.filters[1]
      }
      if (props.filters[2] !== undefined && props.filters[2] !== null) {
        selectedSkills = props.filters[2]
      }

      if (props.filters[3] !== undefined && props.filters[3] !== null) {
        //selectedRatings = props.filters[3][0].split(",").map(Number);
      }
      //console.log("selectedRatings",selectedRatings)
    }
  }, []);

  const handleCategory = (id) => {
    if (selectedCategory.includes(id)) {
      selectedCategory = selectedCategory.filter((item) => item != id);
      if (selectedCategory.length > 0) {
        setSubCateg((prevState) =>
          subCategoriesAll.filter((item) =>
            selectedCategory.includes(item.categories)
          )
        );
        selectedSubCategory = selectedSubCategory.filter((ids) =>
          subCateg.some((item) => item._id == ids)
        );
        setSkillsLists((prevState) =>
          skillsAll.filter((item) => {
            console.log(selectedCategory.includes(item.category));
            return true;
          })
        );
        selectedSkills = selectedSkills.filter((ids) =>
          skillsLists.some((item) => item._id == ids)
        );
      } else {
        selectedSkills = [];
        selectedSubCategory = [];
        setSubCateg((prevState) => subCategoriesAll);
        setSkillsLists((prevState) => skillsAll);
        setUpdateDom((prevState) => prevState + 1);
      }
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        [],
      ]);
    } else {
      //   console.log("allsubCats", allsubCats, "ID", id);
      selectedCategory.push(id);
      console.log("allsubCats", allsubCats, "ID", id, selectedCategory);
      setSubCateg((prevState) =>
        subCategoriesAll.filter((item) =>
          selectedCategory.includes(item.categories)
        )
      );
      setSkillsLists((prevState) =>
        skillsAll.filter((elee) => selectedCategory.includes(elee.category))
      );
    }

    props.handleSearchCounsellor([
      selectedCategory,
      selectedSubCategory,
      selectedSkills,
      selectedRatings,
      [],
    ]);
    setUpdateDom((prevState) => prevState + 1);
  };

  const handleSubCategory = (id) => {
    // debugger;
    if (selectedSubCategory.includes(id)) {
      selectedSubCategory = selectedSubCategory.filter((item) => item != id);
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        [],
      ]);
    } else {
      selectedSubCategory.push(id);
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        [],
      ]);
      setUpdateDom((prevState) => prevState + 1);
    }
  };

  const handleSkills = (item) => {
    console.log("SELECTED SKILLS & FILTERS", selectedSkills, item);
    // debugger;
    if (!selectedSkills.includes(item._id)) {
      selectedSkills.push(item._id);
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        [],
      ]);
      console.log(selectedCategory, selectedSubCategory, selectedSkills);
    } else {
      selectedSkills = selectedSkills.filter((ele) => ele != item._id);
      console.log("REMOVING");
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        [],
      ]);
    }
    setUpdateDom((prevState) => prevState + 1);
    console.log("selectedSkillsselectedSkills",selectedSkills)
  };

  const handleReviews = (id) => {
    console.log("RATINGS", id);
    if (selectedRatings.includes(id)) {
      console.log("ALREADY SELECTED BEFORE ", selectedRatings);
      selectedRatings = selectedRatings.filter((ind) => id != ind);
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        [],
      ]);
    } else {
      selectedRatings.push(id);
      console.log("AFTER SELECTING ", selectedRatings);
      console.log("RATINGS2", id);
      props.handleSearchCounsellor([
        selectedCategory,
        selectedSubCategory,
        selectedSkills,
        selectedRatings,
        [],
      ]);
    }
  };

  const clearAll=(type) =>{
      
    if(type =='category'){
      selectedCategory = [];
    }

    if(type =='subcategory'){
      selectedSubCategory = [];
    }

    if(type =='skill'){
      selectedSkills = [];
    }

    props.handleSearchCounsellor([
      selectedCategory,
      selectedSubCategory,
      selectedSkills,
      selectedRatings,
      [],
    ]);
  }

  return (
    <div className="filter-by">
    {/* Category Section */}
    <div className="skills mt-0 mb-3">
      <h5 className="h5">Filter By Category</h5>
      {selectedCategory.length > 0 && (
        <button onClick={() => clearAll('category')} className="clear-all-btn">
          Clear All
        </button>
      )}
      <div className="u">
        <input
          type="text"
          placeholder="Search Categories"
          className="form-control mb-2"
          onChange={(e) => {
            const val = e.target.value.trim();
            if (val === "") {
              setCats(categoriesAll);
              return;
            }
            const pattern = new RegExp(val, "i");
            setCats(categoriesAll.filter((item) => pattern.test(item.category)));
          }}
        />
        <div className="ems">
          {cats.length > 0 ? (
            <>
              {visibleCategories.map((item, index) => (
                <div className="form-check" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => handleCategory(item._id)}
                    checked={selectedCategory.includes(item._id)}
                  />
                  <label className="form-check-label">{item.category}</label>
                </div>
              ))}
              {cats.length > 10 && (
                <button onClick={() => setShowAll(!showAll)} className="show-more-btn">
                  {showAll ? 'Show Less' : 'Show More'}
                </button>
              )}
            </>
          ) : (
            <p>No categories found</p>
          )}
        </div>
      </div>
    </div>

    {/* Sub Category Section */}
    <div className="skills mt-3 mb-3">
      <h5 className="h5">Filter By Sub Category</h5>
      {selectedSubCategory.length > 0 && (
        <button onClick={() => clearAll('subcategory')} className="clear-all-btn">
          Clear All
        </button>
      )}
      <div className="u">
        <input
          type="text"
          placeholder="Search Sub Categories"
          className="form-control mb-2"
          onChange={(e) => {
            const val = e.target.value.trim();
            if (val === "") {
              setSubCateg(subCategoriesAll);
              return;
            }
            const pattern = new RegExp(val, "i");
            setSubCateg(subCategoriesAll.filter((item) => pattern.test(item.name)));
          }}
        />
        <div className="ems">
          {subCateg.length > 0 ? (
            <>
              {visibleSubCategories.map((item, index) => (
                <div className="form-check" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => handleSubCategory(item._id)}
                    checked={selectedSubCategory.includes(item._id)}
                  />
                  <label className="form-check-label">{item.name}</label>
                </div>
              ))}
              {subCateg.length > 10 && (
                <button onClick={() => setShowSubCatAll(!showSubCatAll)} className="show-more-btn">
                  {showSubCatAll ? 'Show Less' : 'Show More'}
                </button>
              )}
            </>
          ) : (
            <p>No sub-categories found</p>
          )}
        </div>
      </div>
    </div>

    {/* Skills Section */}
    <div className="skills mt-3 mb-3">
      <h5 className="h5">Filter By Skills</h5>
      {selectedSkills.length > 0 && (
        <button onClick={() => clearAll('skill')} className="clear-all-btn">
          Clear All
        </button>
      )}
      <div className="u">
        <input
          type="text"
          placeholder="Search Skills"
          className="form-control mb-2"
          onChange={(e) => {
            let val = e.target.value.trim();
            if (val === "") {
              setSkillsLists(skillsAll);
              return;
            }
            let pattern = new RegExp(`${e.target.value}`, "i");
            setSkillsLists(() =>
              skillsAll.filter((item) => pattern.test(item.skill))
            );
          }}
        />
        <div className="ems">
          {skillsLists.length > 0 ? (
            <>
              {visibleSkills.map((item, index) => (
                <div className="form-check" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => handleSkills(item)}
                    checked={selectedSkills.includes(item._id)}
                  />
                  <label className="form-check-label">{item.skill}</label>
                </div>
              ))}
              {skillsLists.length > 10 && (
                <button onClick={() => setShowSkillAll(!showSkillAll)} className="show-more-btn">
                  {showSkillAll ? 'Show Less' : 'Show More'}
                </button>
              )}
            </>
          ) : (
            <p>No skills found</p>
          )}
        </div>
      </div>
    </div>

    <div className="reviews mt-3 mb-3">
      <h5 className="h5">Filter By Reviews</h5>
      <div className="u">
        <div className="ems">
          {ratings.length > 0 ? (
            ratings.map((item, index) => (
              <div className="form-check" ratings={selectedRatings} key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={() => handleReviews(item)}
                  checked={selectedRatings.includes(Number(item))} // Check with the correct type
                />
                <Rating initialValue={item} readonly={true} />
                <label className="form-check-label">& Above</label>
              </div>
            ))
          ) : (
            <p>No ratings found</p>
          )}
        </div>
      </div>
    </div>

    {/*
      <div className="skills mt-2">
        <Dropdown>
          <Dropdown.Toggle className="drop-btn-costmize " id="dropdown-basic">
            Category
          </Dropdown.Toggle>
          {cats && (
            <Dropdown.Menu className="drop-scroll-costmaize select-categry-sarch-bar">
              <input
                onChange={(e) => {
                  console.log(e.target.value, " THESE ARE CATS", cats);
                  let pattern = new RegExp(`${e.target.value}`, "i");
                  setCats(() => {
                    const aal = categoriesAll.filter((item) =>
                      pattern.test(item.category)
                    );
                    console.log("CCAATTAALLL", aal);
                    return aal.length > 0 ? aal : [];
                  });
                }}
              ></input>
              {cats &&
                cats.map((item, index) => (
                  <div className="drop-costmize " key={index}>
                    <input
                      type="checkbox"
                      onClick={() => {
                        handleCategory(item._id);
                      }}
                      checked={selectedCategory.includes(item._id)}
                    />
                    <label>{item.category}</label>
                  </div>
                ))}
            </Dropdown.Menu>
          )}
        </Dropdown>
      </div>

      <div className="skills mt-2">
        <Dropdown>
          <Dropdown.Toggle className="drop-btn-costmize" id="dropdown-basic">
            Sub Category
          </Dropdown.Toggle>
          {updateDom && (
            <Dropdown.Menu className="drop-scroll-costmaize select-categry-sarch-bar">
              <input
                onChange={(e) => {
                  console.log(e.target.value);
                  let pattern = new RegExp(`${e.target.value}`, "i");
                  setSubCateg(() =>
                    subCategoriesAll.filter((item) => pattern.test(item.name))
                  );
                }}
              />
              {subCateg.map((item, index) => (
                <div className="drop-costmize" key={index}>
                  <input
                    type="checkbox"
                    onClick={() => {
                      handleSubCategory(item._id);
                    }}
                    checked={selectedSubCategory.includes(item._id)}
                  />
                  <label>{item.name}</label>
                </div>
              ))}
            </Dropdown.Menu>
          )}
        </Dropdown>
      </div>

      <div className="skills mt-2">
        <Dropdown>
          <Dropdown.Toggle className="drop-btn-costmize" id="dropdown-basic">
            Skills
          </Dropdown.Toggle>
          {updateDom && (
            <Dropdown.Menu className="drop-scroll-costmaize select-categry-sarch-bar">
              <input
                onChange={(e) => {
                  let val = e.target.value.trim();
                  if (val == "") {
                    setSkillsLists(skillsAll);
                    return;
                  }
                  let pattern = new RegExp(`${e.target.value}`, "i");
                  setSkillsLists(() =>
                    skillsAll.filter((item) => pattern.test(item.skill))
                  );
                }}
              ></input>
              {skillsLists.map((item, index) => (
                <div key={index} className="drop-costmize">
                  <input
                    type="checkbox"
                    onClick={() => {
                      handleSkills(item);
                    }}
                    // checked={() => {
                    //   return selectedSkills.includes(item._id);
                    // }}
                  />
                  <label>{item.skill}</label>
                </div>
              ))}
            </Dropdown.Menu>
          )}
        </Dropdown>
      </div>

      <div className="reviews mt-2">
        <Dropdown>
          <Dropdown.Toggle className="drop-btn-costmize" id="dropdown-basic">
            Reviews
          </Dropdown.Toggle>
          {updateDom && (
            <Dropdown.Menu className="drop-scroll-costmaize select-categry-sarch-bar">
              {ratings.map((item, index) => (
                <div key={index} scroll="true">
                  <input
                    type="checkbox"
                    onClick={() => {
                      console.log(item);
                      handleReviews(item);
                    }}
                  />
                  <Rating initialValue={item} readonly={true}></Rating>
                  <label>{`& Above`}</label>
                </div>
              ))}
            </Dropdown.Menu>
          )}
        </Dropdown>
      </div>
    */}

    </div>
  );
};
export default React.memo(SearchFilter);
