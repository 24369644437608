import UserContext from "../../../contexts/UserContext";
import React, { useState, useContext, useEffect } from "react";
import Walletadd from "../../../data/Walletamount";
import axios from "axios";
import { API } from "../../../api-config";
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import { useLocation } from "react-router-dom";

function Walletscreen() {
  const [wallet, setWallet] = useState([]);
  const [transacts, setTransacts] = useState([]);
  const [amountList, setAmountList] = useState([]);
  const [currency, setCurrency] = useState("");
  const [userType, setUserType] = useState("");
  const [amount, setAmount] = useState(0);
  const [currencyError, setCurrencyError] = useState(false);
  const [availableGateway, setAvailableGateway] = useState(false);
 const [amountError, setAmountError] = useState(false);
  const [dangernotification, setDangerNotification] = useState("");
  const { userData } = useContext(UserContext);
  let addThrot = false;
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const location = useLocation();

  useEffect(() => {
    setCurrency(userData.defaultCurrency);
  }, [userData]);

  useEffect(() => {
    updateUsers();
  }, [userData.userId]);

  useEffect(() => {
    if (location.state) {
      setUserType(location.state.userType);
    }
  }, [location.state]);

  const updateUsers = async () => {
    try {
      // const res = await axios.get(
      //   `${API}/api/wallet/get-amounts/${userData.userId}`,
      //   {
      //     headers: {
      //       token: userData.token,
      //     },
      //   }
      // );

      // const sorted = res.data.sort((a, b) =>
      //   a.currencyCode.localeCompare(b.currencyCode)
      // );
      // console.log('currency ',sorted);
      //   setWallet(sorted);

      const response = await axios.get(
        `${API}/api/currency/get-payment-currency`,
        {
          headers: {
            token: userData.token,
          },
        }
      );
      
      if (response.status === 200) {
        let currencyList = await response.data;
        if (currencyList && currencyList.length > 0) {
           setWallet(currencyList);
           getChangeCurrency(userData.defaultCurrency,currencyList);
        } else {
          console.log("Currency list is empty or not retrieved properly");
        }
      } else {
        console.log("Failed to fetch payment currency data. Status:", response.status);
      }
      
      const transactions = await axios.get(
        `${API}/api/transaction/get-counsellorTransactions`,
        { headers: { userId: userData.userId } }
      );
      setTransacts(transactions.data);
    } catch (error) {
      console.log(error);
    }
  };
const getChangeCurrency = async (currency,currenctList) =>{
  const defaultCurrencyRecord = currenctList.find(item => item.currency === currency);
  if (defaultCurrencyRecord && defaultCurrencyRecord.default_amount.length > 0) {
    setAmountList(defaultCurrencyRecord.default_amount); 
    setCurrency(currency);
  } else {
    console.log("Default currency record is not found or default_amount is empty");
  }

}
const addingAmount = async () => {
  try {
      const res = await axios.get(`${API}/api/gateway/get-currency-allowed-gateways/${currency}`);
      if (res.status === 200) {
          setAvailableGateway(false);
          const params = { amount, currency, userType }; // Example parameters
          navigate('/payment-screen', { state: params });  
      } else {
          setAvailableGateway(true);
      }
  } catch (error) {
      setAvailableGateway(true);
  }
};


  return (
    <>
      {dangernotification && (
        <div className="container mb-3 mt-3">
          <div className="row">
            <div className="alert alert-danger alert-dismissible">
              <a
                onClick={() => setDangerNotification("")}
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                &times;
              </a>
              {dangernotification}
            </div>
          </div>
        </div>
      )}
      <div className="container mt-5 mb-5">
        <div class="top-title mb-4 pt-3">
          <h2 class="heading faq-page-heading">Select Amount to Add</h2>
        </div>
      <div className="center-algne mb-3 mt-3">
        <div className="wallet-card">
          <div className="row">
            <div className="col-12 mb-2">
              <select
                value={currency}
                className={`wallet-amount-btn btn-primary cursor-pointer w-100 mb-2 ${currencyError ? "is-invalid" : ""}`}
                onChange={(event) => {
                  getChangeCurrency(event.target.value,wallet)
                  // setCurrency(event.target.value);
                }}
              >
                <option value="" disabled>
                  Select Currency
                </option>
                {wallet.map((x, index) => (
                  <option key={index} value={x.currency}>
                    {x.currency}
                  </option>
                ))}
              </select>
              {currencyError && (
                <div className="invalid-feedback pb-3">
                  Please select a currency
                </div>
              )}
            </div>
            {availableGateway && (<div className="invalid-feedback d-block pb-3">
            {`No payment gateway for  ${currency} currency`}
            </div>)}
            {amountList.map((item, index) => (
              <div className="col-6 mb-2" key={index}>
                <button
                  className={`wallet-amount-btn w-100 ${amountError ? "is-invalid" : ""}`}
                  type="button"
                  onClick={() => {
                    setAmount(item);
                    setAmountError(false);
                  }}
                >
                  {item}
                </button>
              </div>
            ))}
            <div className="col-6 mb-2">
              <input
                type="number"
                className={`form-control ${amountError ? "is-invalid" : ""}`}
                placeholder="Enter custom amount"
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                  setAmountError(false);
                }}
              />
            </div>
            {amountError && (
              <div className="invalid-feedback d-block pb-3">
                Please select an amount
              </div>
            )}
            <div className="col-12 mb-2">
              <button
                className="wallet-amount-btn w-100"
                onClick={() => {
                  if (!currency && amount == 0) {
                    setCurrencyError(true);
                    setAmountError(true);
                    return;
                  }

                  if (!currency) {
                    setCurrencyError(true);
                    return;
                  }
                  if (amount == 0) {
                    setAmountError(true);
                    return;
                  }

                  if (addThrot) {
                    return;
                  }
                  addThrot = true;
                  setTimeout(() => {
                    addThrot = false;
                  }, 5000);
                  addingAmount();
                }}
              >
                Add Amount
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Walletscreen;
