import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API } from "../../../api-config";
import useRazorpay from "react-razorpay";

function Paymentscreen() {
    const [paymentType, setPaymentType] = useState('');
    const [amount, setAmount] = useState(0);
    const [finalAmount, setFinalAmount] = useState(0);
    const [currency, setCurrency] = useState('');
    const [userType, setUserType] = useState('');
    const { userData } = useContext(UserContext);
    const location = useLocation();
    const Navigate = useNavigate();
    const [Razorpay] = useRazorpay();
    const [paymentTypes,setPaymentTypes] = useState([])
    

    useEffect(() => {
        if (location.state) {
            setCurrency(location.state.currency);
            setAmount(location.state.amount);
            setFinalAmount(location.state.amount);
            setUserType(location.state.userType);
            getpayemntGateways(location.state.currency);
           
            
        }
    }, []);
    const getpayemntGateways = (currency) => {
    axios
        .get(`${API}/api/gateway/get-currency-allowed-gateways/${currency}`)
        .then((res) => {
            if (res.status === 200) {
                const updatedPaymentTypes = [
                    ...res.data,
                    {
                        _id: '670f9506cbced516636a2c97',
                        gateway: 'Direct Payment',
                        charge: 0,
                        notes: '',
                    },
                ];
                setPaymentTypes(updatedPaymentTypes); // Update state immutably
            }
        })
        .catch((err) => console.error(err));
};

    
    const handlePaymentTypeChange = (item) => {
        let finalAmount = 0;
        let chargeAmount = 0;
        setPaymentType(item.gateway);  // Set the selected payment type
        if (item.charge !== 0) {
            chargeAmount = (Number(amount) * item.charge) / 100;  // Ensure `amount` is treated as a number
            finalAmount = Number(amount) + chargeAmount;  // Add the numeric values correctly
            setFinalAmount(finalAmount);  // Update the state to reflect the new amount
        }
    };
    

    const handlePayment = async () => {
        if (paymentType) {
            if (paymentType === 'Razorpay') {
                try {
                    if (finalAmount > 0 && currency !== "") {
                        let paymentDetails = await axios.post(
                            `${API}/api/wallet/get-orderId`,
                            { amount: finalAmount, currency: currency },
                            {
                                headers: {
                                    userId: userData.userId,
                                    entity: "counsellor",
                                    token: userData.token,
                                },
                            }
                        );

                        var options = {
                            key: process.env.RAZORPAY_KEY_ID,
                            amount: finalAmount,
                            order_id: paymentDetails.data.order.id,
                            currency: currency,
                            name: "Ooze IT Solutions Pvt Ltd",
                            handler: async function (paymentDetails) {
                                try {
                                    const addTransaction = await axios.post(
                                        `${API}/api/transaction/add-transaction`,
                                        {
                                            data: {
                                                amount: amount,
                                                userId: userData.userId,
                                                currency: currency,
                                                bidAmount: amount,
                                                transactionType: "CREDIT",
                                                transactionVia: "Razorpay",
                                            },
                                        },
                                        { headers: { token: userData.token } }
                                    );
                                    if (addTransaction?.data?.userId) {
                                        const walletUpdated = await axios.post(
                                            `${API}/api/wallet/addWalletAmountFromRazorpay`,
                                            { userId: userData.userId, amount: amount, currency: currency },
                                            { headers: { token: userData.token } }
                                        );
                                        if (walletUpdated.status === 200) {
                                            setCurrency("");
                                            setAmount(0);
                                            if(userType == 'cousellor'){
                                                Navigate('/counsellor-profile', { state: { show: false, successnotification: "Amount successfully added to your wallet!" } });
                                            }else{
                                                Navigate('/student-profile', { state: { show: false, successnotification: "Amount successfully added to your wallet!" } });
                                            }
                                            
                                        }
                                    }
                                } catch (error) {
                                    console.log(error);
                                }
                            },
                        };
                        const rzp1 = new Razorpay(options);
                        rzp1.open();
                    }
                } catch (error) {
                    console.log(error);
                }
            }
             else if(paymentType === 'Direct Payment'){
                const addTransaction = await axios.post(
                    `${API}/api/transaction/add-transaction`,
                    {
                        data: {
                            amount: amount,
                            userId: userData.userId,
                            currency: currency,
                            bidAmount: amount,
                            transactionType: "CREDIT",
                            transactionVia: "Direct Payment",
                        },
                    },
                    { headers: { token: userData.token } }
                );
                if (addTransaction?.data?.userId) {
                    const walletUpdated = await axios.post(
                        `${API}/api/wallet/addWalletAmountFromRazorpay`,
                        { userId: userData.userId, amount: amount, currency: currency },
                        { headers: { token: userData.token } }
                    );
                    if (walletUpdated.status === 200) {
                        setCurrency("");
                        setAmount(0);
                        if(userType == 'cousellor'){
                            Navigate('/counsellor-profile', { state: { show: false, successnotification: "Amount successfully added to your wallet!" } });
                        }else{
                            Navigate('/student-profile', { state: { show: false, successnotification: "Amount successfully added to your wallet!" } });
                        }
                     }
                }
             }
        } else {
            alert('Please select a payment type');
        }
    };

    const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

    return (
        <>
         {/* New Heading Below the Wallet Card */}
             <div className="container mt-5 mb-5">
                <div className="top-title mb-4 pt-3">
                    <h2 className="heading faq-page-heading">Secure Your Payments</h2>
                    <p className="mt-2 mb-2 payment-heading-subtitle">Choose a payment method that suits your needs.</p>
                </div>
                <div className="container d-flex justify-content-center align-items-center mt-4 mb-4 " >
                    <div className="wallet-card p-4 text-light">
                        <h3 className="mb-4">Payment Gateway Modes</h3>
        
                        {paymentTypes.map((item) => (
                            <div className="mb-3 d-flex justify-content-between align-items-center" key={item._id}>
                                {/* Left: Payment Type Radio Button */}
                                <label
                                    className="gateway-list d-flex align-items-center"
                                    style={{ cursor: 'pointer', width: '50%' }}
                                >
                                    <input
                                        type="radio"
                                        value={item.gateway}
                                        name="payment_type"
                                        checked={paymentType === item.gateway}
                                        onChange={() => handlePaymentTypeChange(item)}
                                        style={{ marginRight: '10px', cursor: 'pointer' }}
                                    />
                                    {capitalize(item.gateway)}
                                </label>
                            </div>
                        ))}
        
                        {/* Find the selected payment type */}
                        {(() => {
                            const selectedPaymentType = paymentTypes.find(item => item.gateway === paymentType);
                            return selectedPaymentType?.notes ? (
                                <div className="mt-3 text-light">
                                    <p className="text-warning">{selectedPaymentType.notes}</p>
                                </div>
                            ) : null;
                        })()}
        
                        <div className="d-flex justify-content-between align-items-end">
                            <button
                                className="btn btn-primary mt-3"
                                onClick={handlePayment}
                            >
                                Proceed with Payment
                            </button>
                            <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                                {currency} {finalAmount}
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
           
        </>
    );
    
}

export default Paymentscreen;
