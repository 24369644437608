const Studentmenu = [
  {
    title: "My Proposals",
    url: "/students",
    cName: "nav-links",
  },
  {
    title: "Top Skills",
    url: "/top-skills",
    cName: "nav-links",
  },
    {
    title: "Find Counsellors",
    url: "/find-counsellor",
    cName: "nav-links",
  },
  {
    title: "Top Counsellors",
    url: "/select",
    cName: "nav-links",
  },
  {
    title: "Upcoming Sessions",
    url: "/upcoming-session-student",
    cName: "nav-links",
  },
  {
    title: "Completed Sessions",
    url: "/completed-session",
    cName: "nav-links",
  },
  {
    title: "Rejected Sessions",
    url: "/rejected-session",
    cName: "nav-links",
  },
  {
    title: "About US",
    url: "/about-us",
    cName: "nav-links",
  },
  // {
  //   title: "FAQs",
  //   url: "/faqs",
  //   cName: "nav-links",
  // },
];

export default Studentmenu;
