import React, { useEffect, useState } from "react";
import "..//..//..//style/partials/Students/_session.scss";
import { useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import SectionsHead from "../../Home/Heading";
import { API } from "../../../api-config";
import axios from "axios";
import moment from "moment";
//import SessionContext from "../../../api/apistudentprofile";
//import { useContext } from "react";

let direction = 1;
let limit = 8;
let offset = 0;
function Studentsession() {
  const navigate = useNavigate();
  //const { session, totalLength } = useContext(SessionContext);
  const [state, setstate] = useState([]);
  const [pagesCount, setPagesCount] = useState(0);
  const [sessionCount, setSessionCount] = useState(0);

  const sessionLoader = async () => {
    const res = await axios.get(`${API}/api/session/get-session`, {
      headers: { len: limit, offset: offset },
    });
    setstate(res.data.allsessions);
    setSessionCount(res.data.totalLength);
    setPagesCount(Math.ceil(res.data.totalLength / 8));
  };
  useEffect(() => {
    sessionLoader();
  }, []);

  const pageNum = async (num) => {
    console.log("num", num);
    limit = 8;
    offset = (num - 1) * 8;
    const sessions = await axios.get(`${API}/api/session/get-session`, {
      headers: { len: limit, offset: offset },
    });
    direction = num;
    setstate([...sessions.data.allsessions]);
  };
  const accomodatePage = (clickedPage) => {
    if (clickedPage <= 1) {
      return clickedPage + 1;
    }

    if (clickedPage >= pagesCount) {
      return clickedPage - 1;
    }
    return clickedPage;
  };
  function Bidrequest(ids, item) {
    navigate(`/session-details/${ids}`);
  }
  return (
    <div className="container session-counseling-div mt-5 mb-5">
      <section>
        <SectionsHead heading="Sessions you might like" />
        <div className=" text-center p-0">
          <div className="row pt-3">
            {/* {console.log(session)} */}
            {state.length > 0 &&
              state.map((x, i) => {
                // console.log("THIS IS X", x);
                return (
                  <div className="col-lg-4 col-xl-3 col-md-6" key={i}>
                    <div className="post-counselling p-3 pad i cursor-pointer" onClick={() => Bidrequest(x._id, x)}>
                      <div className="row">
                        <div className="col-md-10 col-10 post-consel-ditel">
                          <h5>
                            {x.sessiontitle + " by  "}
                            {x.counsellorId.fName != null
                              ? x.counsellorId.fName
                              : "undefined"}
                          </h5>
                          {x.sessionNumber?.map((item, index) => {
                            return index === 0 ? (
                              <p key={index}>
                                Fixed Price :
                                {x.sessionCost ? x.sessionCost : item.Price}
                                {x.defaultCurrency
                                  ? ` ${x.defaultCurrency}`
                                  : ""}
                              </p>
                            ) : null;
                          })}
                          <span key={x.createdAt}>
                            {moment(new Date(x.createdAt)).fromNow()}
                          </span>
                        </div>
                        <div className="col-md-2 col-2 align-self-center cursor-pointer">
                          <BsThreeDots onClick={() => Bidrequest(x._id, x)} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {sessionCount && (
          <div className="btn-div text-center mt-3">
            {direction > 1 && (
              <button
                className="index-btn mb-2 mt-2"
                onClick={() => {
                  pageNum(direction - 1 >= 1 ? direction - 1 : 1);
                }}
              >
                <i className="fa-solid fa-chevron-left"></i>
              </button>
            )}
            {(() => {
              const buttons = [];

              if (pagesCount >= 3) {
                const currentPageNum = accomodatePage(direction);
                for (let i = -1; i < 2; i++) {
                  const pageNumber = currentPageNum + i;
                  buttons.push(
                    <button
                      key={currentPageNum + i}
                      className={`index-btn mb-2 mt-2 ${
                        pageNumber === direction ? "active" : ""
                      }`}
                      onClick={() => {
                        pageNum(currentPageNum + i);
                      }}
                    >
                      {currentPageNum + i}
                    </button>
                  );
                }
              } else {
                for (let i = 0; i < pagesCount; i++) {
                  const pageNumber = 1 + i;
                  buttons.push(
                    <button
                      key={1 + i}
                      className={`index-btn mb-2 mt-2 ${
                        pageNumber === direction ? "active" : ""
                      }`}
                      onClick={() => {
                        pageNum(1 + i);
                      }}
                    >
                      {1 + i}
                    </button>
                  );
                }
              }

              return buttons;
            })()}
            {direction !== pagesCount && (
              <button
                className="index-btn mb-2 mt-2"
                onClick={() => {
                  pageNum(
                    direction + 1 <= pagesCount ? direction + 1 : pagesCount
                  );
                }}
              >
                <i className="fa-solid fa-chevron-right"></i>
              </button>
            )}
          </div>
        )}
      </section>
    </div>
  );
}

export default React.memo(Studentsession);
